.fixed-height-table {
    overflow-y: auto;
    height: calc(100vh - 342px);
    scrollbar-width: thin !important;
    /* overflow-x: hidden!important; */
}

.tableCard {
    margin-top: 0;
    background: #FDFDFD;
    border-radius: 20px !important;
    overflow: hidden;
    border: 0 !important;
}

.tableCard table.table thead th {
    border: 0;
    vertical-align: middle;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #6F8381;
}

.tableCard table.table tbody tr td {
    padding: 12px 19px;
    border-color: #ECEAF3;
    vertical-align: middle;
    border-top: 1px solid #f3f5f7 !important;
}

.tableCard table.table tbody tr td span {
    display: block;
    min-width: 130px;
}

.tableCard table.table .custom-control-input:checked~.custom-control-label::before {
    border-color: #0054FE;
    background-color: #0054FE;
}

.tableCard table.table .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #749cef;
}

.tableCard table.table .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #0f51a196;
}

.tableCard table.table .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgb(15 81 161 / 20%);
}

/* .custom-control-label::before{
    border: #A3A3A3 solid 0.13em!important;
} */

.editBtn {
    border: 0;
    background: #F2FBF8;
    border-radius: 14px;
    width: 150px;
    height: 40px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #464255;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editBtn:hover {
    background: #e2fdf4;
}

.deleteBtn {
    border: 0;
    background: #FDE4E4;
    border-radius: 14px;
    width: 150px;
    height: 40px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: #464255;
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteBtn:hover {
    background: #fbdbdb;
}

.productFormBox {
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
    padding: 30px;
}

.imageUploadBox .imageUploadView {
    width: 100%;
    height: 336px;
    background: linear-gradient(124.48deg, #FCFCFC 7.91%, #F3FCFF 124.02%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 3%);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imageUploadView img {
    width: 200px;
}


.minWidth-180 {
    min-width: 180px;
}

.batchTable tr.batchesHeader {
    background: #F5F9F9;
    border-radius: 8px;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 9;
}

.batchTable tr.batchesHeader th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.batchTable tr.batchesHeader th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tableCard table.table tbody tr.btachRow:hover {
    background: #F6F9F9;
    border-radius: 12px;
}

.tableCard table.table tbody tr.btachRow:hover td {
    background: transparent;
}

.tableCard table.table tbody tr.btachRow:hover td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.tableCard table.table tbody tr.btachRow:hover td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.card.tableCard {
    background: #FFFFFF !important;
    box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%), 0px 0px 7px -2px rgb(16 24 40 / 8%) !important;
    border-radius: 12px !important;
    margin-bottom: 10px;
}

.tabel-outer {
    padding: 14px;
}

.tableCard table.table thead th {
    padding: 15px 16px;
}

.tableCard table.table tbody tr.btachRow:hover .hoverEfect img {
    opacity: 1;
}


.tableCard table.table tbody tr.btachRow .hoverEfect img {
    opacity: 0;
}

.totalRecords {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-left: 18px;
}

.productTable tr.btachRow td .hoverBack {
    opacity: 0;
    position: absolute;
    left: 19px;
    top: 12px;
}

.productTable tr.btachRow:hover td .hoverBack {
    width: 56px;
    height: 56px;
    min-width: 56px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    border-radius: 6px;
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 1;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.tableTextSplit {
    width: 56px;
    height: 56px;
    min-width: 56px;
    background: #00ACB9;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.more-btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00ACB9;
}

.sortBy-custom {
    background: #EFF5F5 !important;
    border: 1px solid #E9F1F0 !important;
    border-radius: 12px !important;
    padding: 13px 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #344054 !important;
    transition: .3s !important;
    border: 0;
}

.sortBy-custom:hover {
    background: #F6F9F9 !important;
    transform: translateY(-8px);
    box-shadow: 0px 5px 4px 0px rgba(222, 227, 227, 0.6);
}

.sortBy-custom:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.batchTable tr.btachRow td .hoverEfect .imgHover_effect:hover {
    background: #FFFFFF;
    border-radius: 32px;
}

td .imgHover_effect{
    transition: .2s;
}

td .imgHover_effect:hover{
    transform: scale(1.1);
}

.batch_add .addBatch-Labels {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667085;
}

.batch_add .form-control {
    background: rgba(231, 231, 231, 0.1);
    border: 1px solid #E7E7E7;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(102, 112, 133, 0.8);
    height: 48px;
    padding: 15px;
}

.batch_add .form-control::placeholder {
    color: rgba(102, 112, 133, 0.5);
}

.batch_add .calneder_icon {
    position: absolute;
    right: 28px;
    top: 44px;
}

/* .batch_add .batch-btn .customCancelBtn {
    background: rgba(102, 112, 133, 0.05);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #262626;
    border: 0;
    height: 48px;
    opacity: 1;
    box-shadow: none;
    filter: none;
} */

.batch_add .batch-btn .customCancelBtn:focus {
    box-shadow: none !important;
}

.batch_add .batch-btn .customSubmitBtn {
    background: #00ACB9;
    box-shadow: 0px 5px 5px rgba(0, 172, 185, 0.08);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    height: 48px;
    border: 0;
    opacity: 1;
}

.imageUploadBox .imageUploadView.batch_img {
    background: rgba(231, 231, 231, 0.1);
    border: 1px solid #E7E7E7;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    position: relative;
    height: 245px;
}

.imageUploadBox .imageUploadView.batch_img .noImage_text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* display: flex;
    align-items: center; */
    text-align: center;
    color: #667085;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imageUploadBox .imageUploadView .noImage_text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #667085;
}

.mb-20px {
    margin-bottom: 20px;
}


/* .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
} */

.add_batch h3 p.breadcrums-top a {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #1E293B !important;
}

.add_batch h3 p.breadcrums-top {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #837B8E !important;
}

.productFormBox .batchDetail {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1E2524;
}

.batch_detail {
    border-bottom: 1px solid #F1F4F3;
    padding: 30px 32px 17px;
}

.batch_detail .editBatchBtn {
    background: #EFF5F5;
    border: 1px solid #E9F1F0;
    border-radius: 10px;
    padding: 9px 19px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    white-space: nowrap;
    width: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.batch_detail .editBatchBtn:hover,
.batch_detail .exportBatchBtn:hover {
    background: #dee3e3 !important;
}

.batch_detail .exportBatchBtn {
    background: #EFF5F5;
    border: 1px solid #E9F1F0;
    border-radius: 10px;
    padding: 9px 11px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.batch_detail .exportBatchBtn:focus {
    box-shadow: none !important;
    outline: none;
}

.batch_detail .exportBatchBtn.dropdown-toggle::after {
    content: none;
}

.batch_detail .batchPrint {
    background: #00ACB9;
    border-radius: 10px;
    border: 0;
    padding: 10px 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    white-space: nowrap;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.batch_detail .batchPrint:hover {
    background: #029fab;
}

.productFormBox .product_add label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgb(102 112 133);
}

.productFormBox .product_add .form-control {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667085;
}

.batch_detailPadding {
    padding: 10px 24px 32px;
}

.batch_history h3.batch-historyHead {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1E2524;
    margin: 35px 0;
}

.batchTable .profile_holder {
    background: rgba(0, 172, 185, 0.8);
    /* position: absolute; */
    width: 36px;
    height: 36px;
    border-radius: 50%;
    /* left: 9px;
    top: 50%; */
    min-width: auto !important;
    /* transform: translateY(-50%); */
    padding: 7px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
}

.batchTable .pl-54px {
    padding-left: 54px !important;
}

.batchTable .batchHistory_table .exportBatchBtn {
    background: #EFF5F5;
    border: 1px solid #E9F1F0;
    border-radius: 10px;
    width: 105px;
}

.batchTable .batchHistory_table .exportBatchBtn::after {
    content: none;
}

.batchTable .batchHistory_table .printButton {
    background: #00ACB9;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    width: 96px;
}

.batchTable .batchHistory_table .printButton:focus,
.batchTable .batchHistory_table .exportBatchBtn:focus,
.batchTable .batchHistory_table .InactiveDropdown:focus {
    box-shadow: none !important;
}

.batchTable .btachRow:hover .batchHistory_table .printButton {
    background: #029fab;
}

.batchTable .batchHistory_table .InactiveDropdown::after {
    content: none;
}

.tableCard table.table tbody tr.history td {
    padding: 20px 19px;
}

.batchTable .batchHistory_table .customDropdownBox .dropdown-menu .dropdown-item.dropItem {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.item-start {
    align-items: center;
}

.batch-header h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.5px;
    color: #1E2524;
}

.batchTable .btachRow.history:hover .batchHistory_table .exportBatchBtn {
    background: #FFFFFF;
    border: 1px solid #99A4A3;
    border-radius: 10px;
}

.editProduct-btn {
    position: absolute;
    right: 23px;
}

.addProductbtn {
    transition: .3s !important;
}

.addProductbtn:hover {
    background: #029fab;
    transform: translateY(-8px);
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs p, .productFormBox.manageVideosGrid.manageVideoMainCard_height .col-md-12.col-vd-nodata p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E2524;
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs p a, .staffListingui .col-vd-nodata p a, .invitedStaffList .no-tr .image-no-data-sm p a{
    color: #00ACB9;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    padding-right: 3px;
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs img {
    max-width: 120px;
}

.tableCard table.table tbody tr:hover td {
    background: transparent !important;
}

h3.breadcrumbDark p.breadcrums-top {
    color: #1E293B !important;
}

.imageUploadBox .imageUploadView.viewbatch {
    width: 100%;
    height: 267px;
}

.batchTable .profile_holder_history {
    background: rgba(0, 172, 185, 0.8);
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    left: 9px;
    top: 50%;
    min-width: auto !important;
    transform: translateY(-50%);
    padding: 7px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
}

.batchesHeader th.unitAvailable {
    padding-left: 35px !important;
}

.table_header {
    width: 18%;
}

.table_header_Name {
    width: 22%;
}

.availableUnits {
    text-align: right;
}

.filter-row {
    /* margin-top: 30px !important; */
    margin-bottom: 25px;
}

.nodatatextp{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E2524;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    #wrapper #sidebar {
        /* display: none; */
        z-index: 999;
    }

    #wrapper #content-wrapper {
        padding: 0 !important;
        margin-right: 0 !important;
        margin-top: 0px !important;
        padding-top: 20px;
    }

    .tableCard table.table thead th {
        padding: 12px 11px !important;
    }

    /* .tableCard table.table tbody tr.btachRow .hoverEfect img {
        opacity: 1;
    } */

    .tableCard table.table tbody tr td {
        padding: 12px 1px;
    }

    .productTable tr.btachRow:hover td .hoverBack {
        left: 28px;
    }

    .tableFilter input[type="search"] {
        width: 308px !important;
    }

    .filter_holder {
        margin-top: 14px;
        margin-bottom: 13px;
    }

    .item-start {
        align-items: start;
    }

    .availableUnits {
        text-align: left;
    }

    .editProduct-btn {
        right: 0px;
    }

    .filter-row {
        margin-bottom: 0px;
    }

    .productFormBox .batchDetail {
        font-size: 17px;
    }

    .tableFilter {
        /* overflow-x: auto; */
    }

    .productFormBox {
        padding: 25px 16px !important;
    }

}

@media screen and (min-width: 640px) and (max-width: 992px) {
    .fixed-height-table {
        height: calc(100vh - 405px);
    }


    .tableFilter input[type="search"] {
        width: 383px !important;
    }

    .filter_holder {
        margin-top: 14px;
        margin-bottom: 13px;
    }

    .item-start {
        align-items: start;
    }

    #wrapper #sidebar {
        /* display: none; */
        z-index: 999;
    }

    #wrapper #content-wrapper {
        padding-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 20px !important;
        overflow-y: unset!important;
        height: auto!important;
    }

    .availableUnits {
        text-align: left;
    }

    .editProduct-btn {
        right: 0px;
    }

    .batchesHeader th.unitAvailable {
        padding-left: 16px !important;
    }

    /* .tableCard table.table tbody tr.btachRow .hoverEfect img {
        opacity: 1;
    } */

    .filter-row {
        margin-bottom: 0px;
    }

    .tableFilter h3 {
        margin: 2px 0 27px 2px !important;
    }
}

@media screen and (min-width:1440px) and (max-width:1500px) {
    /* .productFormBox.batchEdit {
        width: 990px;
    } */

    .ExportCenter {
        text-align: center;
    }

    /* .table_header {
        width: 18%;
    }

    .table_header_Name {
        width: 22%;
    } */
}