.container-fluid-invitaion {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  padding: 15px;
}

#wrapper #content-wrapper #content {
  height: 100vh;
}

.exportRow {
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 35px;
  border-bottom: 1px solid #F1F4F3;
}

.exportRow .button_grp {
  background: #FFFFFF;
  border:1px solid #99A4A3;
  border-radius: 12px;
  /* width: 398px; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 48px;
  overflow: hidden;
}

.exportRow .button_grp button {
  flex: 1 1;
  color: #344054;
  border-radius: 0;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  height: -webkit-fill-available;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 48px;
}

.exportRow .button_grp button.active {
  background: #00ACB9;
  color: #FFFFFF;
}

.exportRow .button_grp button:hover {
  background: #f7f7f7;
}

.exportRow .button_grp button.active:hover {
  background: #00ACB9;
  color: #FFFFFF;
}

.exportRow .button_grp button:active {
  background: #00ACB9;
  color: #FFFFFF;
}

.exportRow .datePickerBox {
  margin-left: 30px;
  position: relative;
}


.exportRow img.calendarIcon {
  position: absolute;
  width: 18px;
  /* height: 30px; */
  transform: translate(-50%, -50%);
  top: 50%;
  left: 25px;
}


.exportRow .datePickerBox input {
  background: #FFFFFF;
  border: 1px solid #99A4A3;
  box-shadow: 0px 3px 3px rgba(16, 24, 40, 0.01);
  border-radius: 12px;
  width: 246px;
  height: 48px;
  padding: 10px 15px;
  color: #344054;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-left: 40px;
}

.exportRow .exportbtn {
  background: #0F51A1;
  border: 1px solid #0F51A1;
  border-radius: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  height: 36px;
  width: 100px;
  outline: 0;
  transition: .1s;
}

.exportRow .exportbtn:hover {
  background: #0e488f;
}

.exportRow .exportbtn:active {
  background: #0c3e7a;
}

.graphDataCard {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.graphDataCard p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #667085;
  margin-bottom: 8px;
}

.graphDataCard h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 15px;
}

.graphDataCard .arrowBox span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1E2524;
  margin-left: 8px;
}

.graphDataCard .chartIcon img {
  width: 100%;
}

.tableCustom {
  background: #FFFFFF;
  border: 1px solid #E1E4E8 !important;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.border-radius_4{
  border-radius: 4px;
}

.productFormBox.reportBatches .daterangepicker td.active, .daterangepicker td.active:hover{
  background-color: #00acb9;
}

@media(max-width:1145px) {
  .exportRow .datePickerBox input {
    width: 180px;
  }

  .exportRow .button_grp {
    /* width: 280px; */
  }

  .exportRow .datePickerBox {
    margin-left: 15px;
  }
}

@media(max-width:940px) {
  .exportRow {
    flex-direction: column;
  }

  .exportRow .button_grp {
    width: 100%;
    overflow: unset;
  }

  .exportRow .datePickerBox {
    margin-left: 15px;
    width: 100%;
  }

  .exportRow .datePickerBox input {
    width: 100%;
  }

  .exportRow .d-flex.align-items-center {
    width: 100%;
    margin-bottom: 15px;
  }

  .exportRow .exportbtn {
    width: 100%;
  }
}

@media(max-width:509px) {
  .exportRow .d-flex.align-items-center {
    flex-direction: column;
  }

  .exportRow .datePickerBox {
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
  }

  .exportRow .button_grp button {
    /* height: 36px; */
  }
}