.batchHistoryTable tr td .batchHistory_table button {
    padding: 0;
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.batchTable .batchHistory_table .EditButton {
    background: #00ACB9;
    border-radius: 10px;
    color: #FFFFFF;
    width: 88px;
}

.batchTable .batchHistory_table .EditButton:focus,
.batchTable .batchHistory_table .exportBatchBtn:focus,
.batchTable .batchHistory_table .InactiveDropdown:focus {
    box-shadow: none !important;
}

.batchTable .btachRow:hover .batchHistory_table .EditButton {
    background: #029fab;
}

.permission_holder .font-book {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #1E2524;
}

.permission-card {
    padding: 32px;
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
    border-radius: 12px !important;
    border: 0 !important;
}

.permission-card form.user_add .form-control-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667085;
}

.moduleDropdown {
    background: rgba(231, 231, 231, 0.1);
    border: 1px solid #E7E7E7;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
}

.assigned-table{
    margin-top: 35px;
    margin-bottom: 35px;
}

.mt-27px{
    margin-top: 27px !important;
}