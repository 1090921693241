.banksCardRow .visaCard {
  /* background-image: url('../img/visaCard.svg'); */
  background-image: url('../../../../assets/img/visaCard.svg');
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.visaCard .validupto {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  top: 22.7%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.visaCard .code {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  bottom: 2%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.visaCard .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 118px;
  bottom: 27px;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.visaCard .cardName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 31%;
  bottom: 3px;
  min-width: 210px;
}

.banksCardRow .MasterCard {
  /* background-image: url('../img/visaCard.svg'); */
  background-image: url('../../../../assets/img/masterCard.svg');
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.MasterCard .validupto {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  top: 22.7%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.MasterCard .code {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  bottom: 2%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.MasterCard .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 118px;
  bottom: 27px;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.MasterCard .cardName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 31%;
  bottom: 3px;
  min-width: 210px;
}

.tooltip-inner {
  background-color: #FFFFFF !important;
  box-shadow: 0px 10px 22px rgba(45, 77, 108, 0.15) !important;
  border-radius: 10px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #464255 !important;
}

.tooltip-arrow {
  left: 50% !important;
  right: 50% !important;
  width: 10px;
  height: 10px;
  position: absolute !important;
  top: 0px !important;
  display: block;
  transform: unset !important;
  background-color: #FFFFFF;
  rotate: 45deg;
}

.cardModal .modal-content .modal-body .cardDetail_head {
  border-bottom: 1px solid #F1F4F3;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.cardModal .modal-content .modal-body .cardDetail_head p.upgradePlan {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  opacity: 1;
  margin: 0;
}

.cardDetail_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardModal .modal-content .modal-body .tierDeatil p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin-bottom: 0;
  text-align: left;
  opacity: 1;
}

.cardModal .closeModalCreatePlan {
  transition: .2s;
}

.cardModal .closeModalCreatePlan:hover {
  transform: rotate(90deg);
}

.cardModal .modal-content .modal-body .tierDeatil .planPrice {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  padding: 15px;
  margin-top: 8px;
  text-align: left;
}

.cardModal .modal-content .modal-body .tierDeatil .planPrice p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #1E2524;
}

.cardModal .modal-content .modal-body .balanceDetail {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardModal .modal-content .modal-body .balanceDetail .charge p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin: 0;
  opacity: 1;
  text-align: left;
}


.cardModal .modal-content .modal-body .balanceDetail .charge span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6F8381;
}

.cardModal .modal-content .modal-body .balanceDetail .balancePrice p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1E2524;
  margin: 0;
  opacity: 1;
}

.cardModal .modal-content .modal-body .cardInfo {
  padding-top: 30px;
}

.cardModal .modal-content .modal-body .cardInfo h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

.cardModal .modal-content .modal-body .cardInfo .cardDetail {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.cardModal .modal-content .modal-body .cardInfo .cardDetail .number {
  padding-left: 15px;
}

.cardModal .modal-content .modal-body .cardInfo .cardDetail .number p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 1;
  margin: 0;
  text-align: left;
  color: #1E2524;
}

.cardModal .modal-content .modal-body .cardInfo .cardDetail .number span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
}

.cardModal .modal-content .modal-body .cardInfo .cardDetail .logo img {
  width: 60px;

}

.cardModal .modal-content .modal-body .cardInfo .cardDetail .custom_radio {
  margin-left: auto;
}

.cardModal .modal-content .modal-body .addMethod {
  display: flex;
  align-items: center;
  padding-top: 30px;
  cursor: pointer;
  padding-bottom: 20px;
}

.cardModal .modal-content .modal-body .addMethod img {
  margin-right: 7px;
}

.cardModal .modal-content .modal-body .addMethod p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
  opacity: 1;
  margin: 0;
  text-align: left;
}

.cardModal.logoutModal.Custom2 .modal-dialog {
  height: auto;
  top: 39px;
}

.cardModal.logoutModal.Custom2 .modal-dialog .modal-footer {
  padding: 0;
}

.cardModal.logoutModal.Custom2 .modal-dialog .modal-footer .terms {
  text-align: left;
  margin: 0;
}