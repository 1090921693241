.termsContainer {
    margin: 0 auto;
}

.logo img {
    width: 217px;
    height: 57px;
}

.termsheading {
    text-align: left;
    margin-left: auto;
}

.termsheading h5 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #0A1F44;
    margin-bottom: 8px;
}

.termsheading p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #8A94A6;
    margin: 0;
}

.terms-page {
    margin-top: 50px;
}

.terms-page h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    color: #0A1F44;
}

.terms-page h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #0A1F44;
    margin-top: 30px;
}

.terms-page ul {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.terms-page ul li {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #8A94A6;
    padding-top: 10px;
    padding-left: 0;
}

@media screen and (max-width:768px) {
    .centerow {
        text-align: center;
    }

    .termsheading {
        margin-top: 10px;
        text-align: center;
    }

    .terms-page {
        margin-top: 0px;
    }

    .terms-page ul {
        padding-left: 0;
        padding-bottom: 15px;
        padding-top: 0;
    }
}