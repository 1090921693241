.manageVideosGrid {
}

.manageVideosGrid .cat_head {
  background: #ffffff;
  border: 1px solid #00acb9;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
  padding: 32px;
  transition: 0.4s;
  height: 100%;
}

.manageVideosGrid .addCategoryBox .cat_head {
  background: #ffffff;
  border: 1px solid #00acb9;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06),
    0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
  padding: 28px;
  transition: 0.4s;
}

.manageVideosGrid .addCategoryBox .cat_head .cat_desc_btn.d-flex.align-items-center.justify-content-between button.btn.can-btn{
  width: 116px;
  height: 40px;
}

.manageVideosGrid .addCategoryBox .cat_head .cat_desc_btn.d-flex.align-items-center.justify-content-between button.btn.save-btn{
  width: 116px;
  height: 40px;
}

.is_published {
  background: #f7f8f9;
  /* background: #e5e5e5; */
}
.cat_head:hover {
  transform: scale(1.05);
  /* transition: 0.5s; */
}
.manageVideosGrid .head_name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(102, 112, 133, 0.7);
  margin-bottom: 0;
}

.manageVideosGrid h4.head_description.cat-desc-head.cursor-pointer {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 12px;
  margin-bottom: 10px;
  height: auto;
}

.manageVideosGrid .cat_desc {
  padding-top: 49px;
}

.manageVideosGrid .cat_desc .num_head .head_name {
}
.manageVideosGrid .cat_desc .num_head .head_description {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  margin-top: 12px;
  margin-bottom: 25px;
}

.manageVideosGrid .cat_desc .num_head:nth-child(2) .head_description,
.manageVideosGrid .cat_desc .num_head:nth-child(2) .head_description {
  margin-bottom: 0 !important;
}

.manageVideosGrid .cat_desc .num_head .head_name.published {
  color: rgba(36, 106, 24, 0.7);
}

.manageVideosGrid .cat_desc .num_head .head_name.unpublished {
  color: rgba(198, 15, 15, 0.7);
}

.customDropdownVideo.dropdown {
}

.productFormBox.manageVideosGrid .cardStatus {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1px;
  color: #344054;
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 8px;
  width: 90px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.productFormBox.manageVideosGrid .cardStatus::after{
  content: "";
}
.customDropdownVideo.dropdown button {
  padding: 0;
  margin: 0;
  height: 15px;
  display: flex;
  margin-top: -8px;
  margin-right: -3px;
}
.customDropdownVideo.dropdown button:focus {
  box-shadow: none;
  outline: 0;
}
.customDropdownVideo.dropdown button.dropdown-toggle::after {
  display: none;
}
.customDropdownVideo.dropdown button img {
  width: 20px;
}

.customDropdownVideo.customDropdownBox .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #464255;
}

.customDropdownVideo.customDropdownBox .dropdown-menu .dropdown-item img {
  margin-right: 7px;
}

button.btn.save-btn {
  height: 42px;
  /* background: linear-gradient(116.35deg, rgba(190, 242, 245, 0.1) 0%, #B8E3FB 107.69%); */
  /* border: 1px solid #FFFFFF;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px; */
  letter-spacing: 0.05em;
  /* color: #464255;
  opacity: 0.75; */
  width: 100%;
  background: #00acb9;
  box-shadow: 0px 5px 5px rgb(0 172 185 / 8%);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

button.btn.can-btn {
  height: 42px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 14px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  letter-spacing: 0.05em;
  /* color: #464255; */
  /* opacity: 0.75; */
  width: 100%;
  /* border: 2px solid #C4E6FC; */
  /* filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05)); */
  cursor: pointer;

  background: rgba(102, 112, 133, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  border: 0;
  box-shadow: none;
}

.addlablebox {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: #8a94a6;
  margin-bottom: 10px;
}
