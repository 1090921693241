.form-head {
  font-family: 'Poppins';
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #0a1f44;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.customEditbtn {
  background: #EFF5F5;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 0;
  border-radius: 12px;
  width: 90px;
  height: 48px;
  margin-left: 16px;
  transition: .3s !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customEditbtn img {
  margin-right: 8px;
}