.user_add {
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.footer-form {
  text-align: center;
  margin-top: 2.5%;
  margin-bottom: 2.5%;

}

.skeleton-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mob-hide {
  display: block;
}

.mob-show {
  display: none;
}

.action-btn {
  margin-right: 12px;
  border-radius: 5px;
  border: solid 1px #ffffff !important;
  background-color: #0f51a1 !important;
  padding: 7px 40px;
  font-family: CircularStd-Book;
  font-size: 12px;
  color: #ffffff !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}


form.user_add .form-control-user {
  font-size: 14px;
  font-family: CircularStd-Medium;
  border-radius: 4px;
  padding: 0rem 0rem;
  color: #4a4a4a;
}

form.user_add .form-control-input {
  font-size: 14px;
  font-family: CircularStd-Medium;
  border-radius: 4px;
  padding: 0rem 0.25rem;
  color: #4a4a4a;
}

form.user_add .form-control-user[readonly] {
  background: #fff;
  color: #8a94a6;
}

/* form.user_add .form-control-label {
  font-size: 12px;
  font-family: CircularStd-Book;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #8a94a6;
} */

.img-col-user {
  margin-left: 15px;
  height: 100%;
}

.img-col-user {
  margin-top: 0;
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
}

.view-img-user {
  display: flex;
  justify-content: center;
}

.user-img-tile {
  width: 150px;
  height: 150px;
  text-align: center;
}

.user-img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 4px;
}

.file-upload-btn {
  margin-left: 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.img-col-user .file-upload-btn img {
  margin: 0;
}

.pagination {
  display: inline-block;
}

.right {
  text-align: right;
}

.pagination a {
  /* font-size: 14px;
  color: #8a94a6; */
  float: left;
  margin: 0px 2px;
  padding: 2px 10px;
  /* text-decoration: none; */
  border: 0;
  /* border-radius: 3px; */
  background: transparent;
  width: 35px;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #344054!important;
  font-weight: 500;
  text-decoration: none;
}

.pagination a:hover, .pagination a:focus{
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  text-decoration: none;
}

.pagination a.active {
  border: 1px solid #8a94a6;
  color: #0a1f44;
}

/* .pagination a:hover:not(.active) {
  border: 1px solid #8a94a6;
} */

.user_detail .actionBtn {
  background: #EFF5F5;
  border: 1px solid #E9F1F0;
  border-radius: 12px;
  color: #344054;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 109px;
  height: 48px;
}

@media (max-width: 768px) {

  .img-col-user {
    margin-left: 0px;
    margin-top: 0%;
    margin-bottom: 2.5%;
  }

  .mob-hide {
    display: none;
  }

  .mob-show {
    display: block;
  }

}