.productFormBox.faqBox .faqRow h4.faq-head {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #1E2524;

}

.productFormBox.faqBox .faqRow h4.faq-head span {
    color: #00ACB9;
}

.accrordianHolder .leftAccordion .moduleList li.active {
    background: var(--secondaryBg);
    color: var(--primaryBg) !important;
    border-left: 2px solid var(--primaryBg) !important;
    border-radius: 0px 8px 8px 0px;
}

.accrordianHolder .leftAccordion .moduleList li {
    list-style: none;
    width: 207px;
    height: 48px;
    align-items: center;
    display: flex;
    padding: 0 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.accrordianHolder {
    padding: 16px 0;
}

.accrordianHolder .rightAccordion {
    margin-left: 135px;
    width: 565px;
}

.accrordianHolder .rightAccordion .accordion-item {
    border-bottom: 1px solid #F1F4F3;
    padding-bottom: 20px;
    padding-top: 30px;
    cursor: pointer;
}

.accrordianHolder .rightAccordion .accordion-item:first-child {
    padding-top: 0px;
}

.accrordianHolder .rightAccordion .accordion-item:last-child {
    border-bottom: 0;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1E2524;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-title .icon {
    margin-left: auto;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-title .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.5px;
    color: #1E2524;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-content.show {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    padding-right: 34px;
    padding-top: 10px;
    transition: all 3s ease;
    height: auto;
    visibility: visible;
    transition: all 0.5s ease;
    opacity: 1;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-content.hide {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
}

.iconHolder {
    border: 1px solid #EFF5F5;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    padding: 7px;
}

.iconHolder img {
    width: 14px;
    cursor: pointer;
}

.iconHolder img.cross {
    transform: rotate(45deg);
}

.no-data p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #1E2524;
}

.productFormBox.help.tutorials .videoCard .cardFooter a.seeVideo {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #00acb9;
}