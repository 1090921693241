.img-col-user{
  height: 100%;
  min-height: 150px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-col-user .user-img {
  max-width: 100%;
  max-height: 280px;
  border-radius: 20px;
}

.dltimgbtn{
    height: 45px;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
    border-radius: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #464255;
    opacity: 0.75;
    width: 53px;
    border: 2px solid #fb8283;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05));
    display: flex;
    align-items: center;
    justify-content: center;
}

.productFormBox .progress{
  height: 0.5rem;
}

.productFormBox .progress-bar{
  background-color: #0f51a1;
}

.productFormBox .css-1okebmr-indicatorSeparator{
  display: none!important;
}