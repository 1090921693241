/* .page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: CircularStd-Book;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: CircularStd-Book;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.ploc_scan {
  width: 300px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
}

.pname_scan {
  width: 220px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-line;
} */

.tableCard table.table thead tr th {
  white-space: nowrap;
}

@media screen and (min-width: 1440px) and (max-width:1500px){
  .tableCard table.table tbody tr.scan td {
    padding: 20px 16px !important;
  }
}