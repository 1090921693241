/* page header css */

:root {
  --primaryBg: #00acb9;
  --secondaryBg: #e5fdff;

  --primaryColor: #0b0448;
  --secondaryColor: #837b8e;
  --tertiaryColor: #6f6a88;
}

.pageHeaderRow h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 4px;
}

.pageHeaderRow h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #837b8e;
  margin-bottom: 0;
}

.tableCard table.table tbody tr:first-child td {
  border-color: transparent;
  border-top: 1px solid transparent !important;
}

.productFormBox {
  padding: 25px 33px !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%) !important;
  border-radius: 10px !important;
}

.productFormBox label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin-bottom: 8px;
  padding-left: 4px;
}

.productFormBox.manageVideosGrid .addCategoryBox label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(102, 112, 133, 0.7);
  margin-bottom: 15px;
}

.productFormBox.manageVideosGrid.manageVideoMainCard_height {
  padding: 33px 33px !important;
  min-height: calc(100vh - 342px);
  position: relative;
}

.productFormBox.manageVideosGrid.manageVideoMainCard_height .col-md-12.col-vd-nodata {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.productFormBox.manageVideosGrid.manageVideoMainCard_height .col-md-12.col-vd-nodata p {
  margin-bottom: 0;
}

form .form-control,
.addVideoFormInput {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: 48px;
  padding-left: 14px;
  outline: 0;
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%);
  border-radius: 10px;
  color: #667085 !important;
}

.addVideoFormInput {
  width: 100%;
}

.form-control:focus {
  background: linear-gradient(89.93deg,
      rgba(239, 245, 245, 0.352) -13.9%,
      rgba(255, 255, 255, 0.8) 20.68%,
      rgba(254, 254, 254, 0.8) 80.83%,
      rgba(239, 245, 245, 0.36) 118.86%) !important;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 0px 2px #00acb9 !important;
  transition: 0.01s;
}

form .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(102, 112, 133, 0.5);
  opacity: 1;
  /* Firefox */
}

form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(102, 112, 133, 0.5);
}

form .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(102, 112, 133, 0.5);
}

form .customCancelBtn {
  background: #ffffff;
  box-shadow: none;
  border-radius: 10px;
  border: 1px solid #e9f1f0;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #344054;
  letter-spacing: 0;
  opacity: 1;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.02));
}

.customCancelBtn {
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 10px;
  border: 1px solid #e9f1f0;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  transition: .3s;
}

.formLeftSection .glassEffects form label.custom-control-label {
  color: rgba(102, 112, 133, 0.8);
}

form button.customCancelBtn.choosebtb {
  color: #00acb9;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 10px;
  border: 1px solid #00acb9;
}

form .customCancelBtn.attachmentbtn {
  border: 1px solid #00acb9;
  border-radius: 10px;
}

.imageUploadBox .imageUploadView {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%);
  border-radius: 10px;
}

.productFormBox .prodductItems {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
}

.productFormBox .prodductItems img {
  font-size: 8px;
  border-radius: 5px;
}

/* .tableFilter h3 p.breadcrums-top a, .tableFilter h3 p.breadcrums-top{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1E2524;
} */

.tableFilter h3 {
  margin: 2px 0 55px 2px;
  width: 100%;
}

.productFormBox form .form-control,
.productFormBox .addVideoFormInput {
  font-size: 14px !important;
}

.productFormBox form textarea.form-control {
  min-height: 220px;
}

.productFormBox.viewStaff_page form textarea.form-control {
  min-height: 99px;
}

.productFormBox.manageVideosGrid form textarea.form-control {
  min-height: 177px !important;
}

.formLeftSection .glassEffects form button.btn.customSubmitBtn {
  min-width: 400px;
}

.formLeftSection .glassEffects form .buttonSetPassword button {
  min-width: auto !important;
  opacity: 1;
}

.addProductbtn {
  min-width: 131px;
  padding: 13px 16px !important;
}

.addProductbtn.addvideobtn {
  min-width: 155px;
}

.container-fluid h1.h3-head-mob.d-md-none {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.5px;
  color: #0b0448;
  padding: 0.5rem 0;
}

.bg-gradient-primary {
  background: #fbfcfc;
}

form .form-group .a-link {
  color: var(--primaryBg);
  text-decoration: none;
}

.assigneeFilter .addProductbtn.normalbtn {
  color: #344054;
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  padding: 9px 15px !important;
  min-width: 107px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assigneeFilter .customDropdownBox .dropdown-custom.dropdown-toggle {
  padding: 7px 15px !important;
  height: 40px;
  color: #344054;
  font-weight: 600;
  font-size: 14px;
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px !important;
}

.customDropdownBox .dropdown-menu .dropdown-item:hover {
  color: var(--primaryBg) !important;
}

.uploadImgNote {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin: 6px 0 0 0;
  padding: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 1 !important;
  background: rgba(102, 112, 133, 0.3) !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03) !important;
  color: #fff !important;
  border: 0 !important;
}

button.swal2-confirm.swal2-styled {
  background-color: rgb(0 172 185) !important;
  border-left-color: rgb(0 172 185) !important;
  border-right-color: rgb(0 172 185) !important;
}

button.swal2-confirm.swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgb(0 172 185 / 30%) !important;
}

.css-1wy0on6 {
  display: none !important;
}

/* body{
  overflow: hidden;
} */

#wrapper #content-wrapper::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
}

/* Track */
#wrapper #content-wrapper::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#wrapper #content-wrapper::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
#wrapper #content-wrapper::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

li.nav-item.active.active-show span.nav-link.sub-link.cursor-pointer span {
  background: transparent;
}

.imageBoxView span.cursor-pointer.left-ar-counterfeit {
  left: 6% !important;
}

.imageBoxView span.cursor-pointer.right-ar-counterfeit {
  left: 91% !important;
}

.card-map {
  border: 0 !important;
}

.card-map .card-header {
  padding: 25px 33px !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%) !important;
  border-radius: 10px !important;
  border: 0;
}

.card-map .card-header h6.font-book {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1e2524;
  margin: 0px 0px 25px;
}

.card-map .card-header .mapboxgl-control-container {
  display: none;
}

.manage_video_row button.printButton.Unpublishbtn {
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  width: 115px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
  transition: .2s;
  margin: 0 !important;
}

.manage_video_row button.printButton.Unpublishbtn:hover {
  background: #ffffff;
  opacity: .9;
  transform: scale(1.03);
}

.manage_video_row button.printButton.publishbtn {
  width: 115px;
  height: 40px;
  left: 24px;
  top: 20px;
  background: #00acb9;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  transition: .2s;
  margin: 0 !important;
}

.manage_video_row button.printButton.publishbtn:hover {
  background: #039fab;
  transform: scale(1.03);
}

.tableCard table.table tbody tr.btachRow.manage_video_row:hover button.printButton.Unpublishbtn {
  background: #fff;
  border: 1px solid #99A4A3;
}

h3.custombreadcrumbMargin {
  margin: 2px 0 55px 2px !important;
}


.dashboardPage .mycard.minicards .d-flex.align-items-center div img {
  width: 50px;
}

.customDropdownBox .dropdown-menu {
  max-height: 240px;
  overflow: auto;
}

/* .productFormBox.manageVideosGrid.manageVideoMainCard_height .col-md-12.col-vd-nodata{
  padding: 7em 0px;
} */

.settingModal .modal-dialog .modal-content {
  /* background: transparent; */
  background: linear-gradient(180deg, #00ACB9 23.76%, rgba(17, 228, 244, 0.37) 116.51%);
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06),
    0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px 12px 12px 0px;
}

.settingModal .modal-dialog .modal-content .modal-header {
  padding: 32px 32px 0px;
  border: 0;
}

.settingModal .modal-dialog .modal-content .settingModal_header {
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: center;
}

.settingModal .modal-dialog .modal-content .coverHolder {
  background: #FFFFFF;
  border-radius: 8px;
  width: 166px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #00ACB9;
  cursor: pointer;
}

.settingModal .modal-dialog {
  max-width: 772px;
}

.settingModal .modal-dialog .modal-content .settingModal_header .closeSetting {
  background: #FFFFFF;
  border: 1px solid #EFF5F5;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.settingModal .modal-dialog .modal-content .modal-body .image-holder .user-image {
  width: 243.25px;
  height: 243.25px;
  /* overflow: hidden; */
  border-radius: 50%;
  justify-content: center;
  position: relative;
}

.settingModal .modal-dialog .modal-content .modal-body .image-holder {
  align-items: center;
  justify-content: center;
  display: flex;
}

.settingModal .modal-dialog .modal-content .modal-body .image-holder .user-image .img {
  width: 245px;
  border-radius: 50%;
  height: 245px;
}

.settingModal .modal-dialog .modal-content .modal-body .image-holder .imageEdit {
  background: #FFFFFF;
  border-radius: 50%;
  width: 34.88px;
  height: 34.88px;
  position: absolute;
  bottom: 44px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingModal .modal-dialog .modal-content .modal-body .image-holder .imageEdit label {
  margin: 0;
}

.settingModal .modal-dialog .modal-content .modal-body .companyName {
  text-align: center;
  padding-top: 16px;
}

.settingModal .modal-dialog .modal-content .modal-body .companyName h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
}

.settingModal .modal-dialog .modal-content .modal-body .companyName h5 .heading-selector {
  background: #FFFFFF;
  width: 2px;
  height: 29px;
  display: inline-block;
  margin: 0 8px;
  position: relative;
  top: 7px;
}

.settingModal .modal-dialog .modal-content .modal-body {
  padding: 32px !important;
  /* box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08); */
  border-radius: 12px 12px 12px 0px;
  background-position: center;
  background-size: cover;
}

.settingModal .modal-dialog .modal-content .modal-body .moduleHolder {
  text-align: center;
}

.settingModal .modal-dialog .modal-content .modal-body .moduleHolder .moduleLink {
  text-decoration: none;
  color: #00ACB9;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;

}

.settingModal .modal-dialog .modal-content .modal-body .moduleHolder .moduleName {
  background: #FFFFFF;
  border-radius: 8px 8px 8px 0px;
  width: 286px;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  margin: 30px auto 0;
  position: relative;
}

.settingModal .modal-dialog .modal-content .modal-body .moduleHolder .moduleName p {
  margin: 0;
  padding-left: 10px;
}

.settingModal .modal-dialog .modal-content .modal-body .moduleHolder .moduleName .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.sidebarOptions li a.main {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #AEBEBC;
  padding-left: 13px;
  margin-bottom: 15px;
  display: block;
  height: auto;
  padding: 0;
  text-decoration: none;
}

.sidebarOptions li a.main:hover {
  background-color: transparent;
}

.billings .exportRow {
  margin-bottom: 0px;
}

.billings .exportRow h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin: 0;
}

.billings .innerHolder p.listHeading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #6F8381;
  margin: 0;
  position: absolute;
  top: -10px;
}

.billings .billingInviceCard.active {
  background: #FFFFFF;
  border: 1px solid #00ACB9;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
  margin-top: 25px;
  padding: 0 32px;
  opacity: 1;
}

.billings .billingInviceCard {
  opacity: 0.7;
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
  margin-top: 35px;
  padding: 0 32px;
}

.billings .billingInviceCard .CardHeader {
  border-bottom: 1px solid #F1F4F3;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 0 11px;
}

.billings .billingInviceCard .CardHeader h4 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

/* .switchBox .d-flex.align-items-center.justify-content-between{
  margin-bottom: 30px;
}

.switchBox .d-flex.align-items-center.justify-content-between:last-child{
  margin-bottom: 0;
} */

.billings .billingInviceCard .CardHeader .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  margin: 0;
}

.billings .billingInviceCard .CardHeader .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.billings .billingInviceCard .CardHeader .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.billings .billingInviceCard .CardHeader .slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  transition: .4s;
}

.billings .billingInviceCard .CardHeader input:checked+.slider {
  background-color: #00ACB9;
}

.billings .billingInviceCard .CardHeader input:focus+.slider {
  box-shadow: 0 0 1px #00ACB9;
}

.billings .billingInviceCard .CardHeader input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.billings .billingInviceCard .CardHeader .slider.round {
  border-radius: 34px;
}

.billings .billingInviceCard .CardHeader .slider.round:before {
  border-radius: 50%;
}

.billings .billingInviceCard .CardHeader .rightSection .buttonHolder button {
  width: 100px;
  height: 40px;
  background: #00ACB9;
  border-radius: 10px;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 0;
  transition: .3s !important;
}

.billings .billingInviceCard .CardHeader .rightSection .buttonHolder button:hover {
  color: #ffffff;
  text-decoration: none;
  transform: translateY(-8px);
}

.billings .billingInviceCard .CardHeader .rightSection .buttonHolder button img {
  margin-right: 10px;
}

.billings .billingInviceCard .CardHeader .rightSection {
  display: flex;
  align-items: center;
}

.billings .billingInviceCard .CardHeader .rightSection .switchHolder {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.billings .billingInviceCard .CardHeader .rightSection .switchHolder .renew {
  padding-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1E2524;
}

.billings .billingInviceCard .CardBody {
  padding: 29px 0 5px;
}

.billings .billingInviceCard .CardBody .invoice_list {
  padding: 0;
}

.billings .billingInviceCard .CardBody .invoice_list li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.billings .billingInviceCard .CardBody .invoice_list li .detail {
  padding: 0px 12px;
  white-space: nowrap;
}

.billings .billingInviceCard .CardBody .invoice_list li .detail span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #667085;
}

.billings .billingInviceCard .CardBody .invoice_list li .detail p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* line-height: 22px; */
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 6px;

}

.billings .billingInviceCard .CardBody .invoice_list li .change {
  margin-top: 20px;
  /* position: absolute; */
  left: 161px;
}

.billings .billingInviceCard .CardBody .invoice_list li .change span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-decoration: underline;
  color: #00ACB9;
  cursor: pointer;
}

.col-md-3 .form-control.h-100.css-b62m3t-container .css-1rsjho4-control,
.col-md-3 .form-control.h-100.css-b62m3t-container .css-x19aeh-control {
  background-color: #fdfdfd;
  background: #fdfdfd;
  border: 0;
  border-radius: 20px;
  width: 100%;
}

.statusModal .modal-dialog {
  max-width: 709px;
}

.statusModal .modal-dialog .modal-content {
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
}

.statusModal .modal-dialog .modal-content .modal-header {
  padding: 0 0 19px;
}

.statusModal .modal-dialog .modal-content .modal-header h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1E2524;
  margin: 0;
}

.statusModal .modal-dialog .modal-content .modal-body {
  padding: 29px 0 0;

}

.statusModal .modal-dialog .modal-content .modal-body .statusList ul {
  padding: 0;
}

.statusModal .modal-dialog .modal-content .modal-body .statusList ul li {
  list-style: none;
}

.statusModal .modal-dialog .modal-content .modal-body .statusList ul li label .select {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-left: 34px;
}

.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .checkmark {
  position: absolute;
  top: 5px;
  /* left: 0; */
  height: 25px;
  width: 25px;
  border: 1px solid #E7E7E7;
  border-radius: 16px;

}

/* On mouse-over, add a grey background color */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel:hover input~.checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel input:checked~.checkmark {
  background: #00ACB9;
  border-radius: 16px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.statusModal .modal-dialog .modal-content .modal-body .statusList ul li .chechboxLabel .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.statusModal .modal-dialog .modal-content .modal-body .statusList ul li p.desc {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #6F8381;
  padding-left: 35px;
}

.statusModal .modal-dialog .modal-content .modal-body .modal-footer {
  display: flex;
  justify-content: center;
  border: 0;
  padding: 0;
}

.statusModal .modal-dialog .modal-content .modal-body .modal-footer button {
  width: 200px;
  height: 48px;
}


.paymentSection .paymentRow .chechboxLabel {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.paymentSection .paymentRow .chechboxLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.paymentSection .paymentRow .checkmark {
  position: absolute;
  top: 0px;
  /* left: 0; */
  height: 25px;
  width: 25px;
  border: 1px solid #E7E7E7;
  border-radius: 16px;

}

/* On mouse-over, add a grey background color */
.paymentSection .paymentRow .chechboxLabel:hover input~.checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.paymentSection .paymentRow .chechboxLabel input:checked~.checkmark {
  background: #00ACB9;
  border-radius: 16px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.paymentSection .paymentRow .chechboxLabel .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.paymentSection .paymentRow .chechboxLabel input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.paymentSection .paymentRow .chechboxLabel .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.paymentSection .paymentRow .chechboxLabel .select {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-left: 34px;
}

.paymentSection .paymentRow .sliderHolder {
  margin-top: 15px;
  margin-bottom: 27px;
}

/* .paymentSection .paymentRow .sliderHolder .slick-dots {
  background: #EEF0F4;
  border-radius: 4px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
}

.paymentSection .paymentRow .sliderHolder .slick-dots li.slick-active button:before {
  content: '';
  background: #00ACB9;
  height: 4px;
  top: -8px;
}

.paymentSection .paymentRow .sliderHolder .slick-dots li button:before {
  content: '';
  height: 4px;
  top: -8px;
} */

.slick-slide img.cards {
  width: 90%;
}

/* .slick-active {
  background: #00ACB9;
  border-radius: 4px;
} */

.cardHolder {
  padding-top: 41px;
}

.cardHolder .cardEditor {
  display: none;

}

.cardEditor img {
  cursor: pointer;
  margin-right: 28px;
}

.cardEditor img:first-child {
  margin-left: 10px;
}

.cardHolder:hover .cardEditor {
  display: flex;
  opacity: 1;
  margin-bottom: 22px;
  position: absolute;
  top: 3px;
  z-index: 99999;
}

.methodRow .accrordianHolder .rightAccordion {
  margin: 0;
  width: 100%;
}

.methodRow .accrordianHolder .rightAccordion .iconDrop {
  transition: all 0.5s ease;
  margin-right: 10px;
}

.methodRow .accrordianHolder .rightAccordion .iconDrop.active {
  transition: all 0.5s ease;
  rotate: 180deg;
}

.methodRow .accrordianHolder .rightAccordion .innerCard {
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
  padding: 32px;
}

.methodRow .accrordianHolder .rightAccordion .innerCard .CardHeader {
  border-bottom: 1px solid #F1F4F3;
  padding-bottom: 20px;
}

.methodRow .accrordianHolder .rightAccordion .innerCard .CardHeader h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #1E2524;
}

.methodRow .accrordianHolder .rightAccordion .innerCard .CardHeader p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #6F8381;
}

.methodRow .accrordianHolder .rightAccordion .innerCard .CardBody {
  padding-top: 30px;
}

/* .slick-list {
  overflow: unset !important;
}

.innerHolder.paymentRow {
  overflow: hidden;
} */

.accordion-title .title.active {
  opacity: 0.5;
}

.accordion-title .title {
  opacity: 1;
}

img.rotate {
  rotate: 180deg !important;
}

.rotateAgain {
  rotate: 360deg !important;
}

.graphcard .cardBody.card_overflow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: auto !important;
  max-height: none !important; */
}

.graphcard .cardBody .dataHolder {
  position: absolute;
  display: block;
  text-align: center;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.graphcard .cardBody .dataHolder h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

.graphcard .cardBody .dataHolder p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6F8381;
}

.planGrid .btn_wrap button:disabled,
button[disabled] {
  background: rgba(102, 112, 133, 0.3) !important;
  border: 0 !important;
}

.sliderHolder .cardHolder .slider_inner .card-back.VisaCard {
  background: linear-gradient(116.12deg, #6B63D2 1.34%, #6BADDD 98.2%);
  border-radius: 14px;
  padding-bottom: 160px;
  width: 400px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.sliderHolder .cardHolder .slider_inner .card-back .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  padding-top: 15px;
}

.sliderHolder .cardHolder .slider_inner .card-back .header .ellips .visaEllips {
  position: absolute;
  top: 0;
  left: 0;
}

.sliderHolder .cardHolder .slider_inner .card-back .header .validHodler p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.sliderHolder .cardHolder .slider_inner .card-back .header .ellips .cardName {
  position: absolute;
  top: 34px;
  left: 46px;
}

.sliderHolder .cardHolder .slider_inner .card-back .footer {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 52px 0 32px;
}

.sliderHolder .cardHolder .slider_inner .card-back .footer .detailHolder {
  z-index: 99999;
}

.sliderHolder .cardHolder .slider_inner .card-back .footer .detailHolder p.accountNo {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.sliderHolder .cardHolder .slider_inner .card-back .footer .detailHolder p.name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.sliderHolder .cardHolder .slider_inner .card-back .footer .secretDetail p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.sliderHolder .cardHolder .slider_inner .card-back.VisaCard .footer .ellipsBottom {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.sliderHolder .cardHolder .slider_inner .card-back.MasterCard {
  background: #242424;
  border-radius: 14px;
  padding-bottom: 160px;
  width: 400px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.sliderHolder .cardHolder .slider_inner .card-back.MasterCard .header .ellips .ellipsTop {
  position: absolute;
  top: 0;
  left: 32px;
}

.sliderHolder .cardHolder .slider_inner .card-back.MasterCard .footer .trangleBottom {
  position: absolute;
  bottom: -10px;
  left: 20px;
}

.sliderHolder .cardHolder .slider_inner .card-back .header .brand_name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  position: absolute;
  left: 34px;
  top: 29px;
  z-index: 9999999999;
}

.comparisonHide {
  display: none;
}

.comparisonShow {
  display: block;
}


.hoverEffect:hover {
  color: #6F8381;
  background: #d6d6d7;
  cursor: pointer;
}

.arrow_box {
  color: #ffffff;
  background: #00ACB9;
  padding: 5px;
}

.bottom_box {
  color: #000000;
  background: #E5FDFF;
  padding: 5px;

}


.slick-track {
  margin-left: 0 !important;
}

.avtarBox a {
  position: unset !important;
  display: unset !important;
  height: auto !important;
  padding: 0 !important;
}

.avtarBox a:hover {
  background-color: transparent !important;
}

.dashboardPage .subGraphs.mycard .cardHeader {
  padding: 32px 32px 20px;
}

.renewPadding {
  padding-bottom: 14px !important;
  padding-top: 16px !important;
}

.insideHolder {
  /* display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; */
}

.insideHolder .inside {
  display: flex;
  align-items: center;
}

.insideHolder label {
  display: flex !important;
  align-items: center !important;
}

.insideHolder label input {
  margin-right: 10px;
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs p span,
.staffListingui .col-vd-nodata p span,
.invitedStaffList .no-tr .image-no-data-sm p span {
  color: #00ACB9;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  min-width: unset;
  cursor: pointer;
  padding-right: 3px;
}

.tableCard table.table tbody tr.no-tr td.image-no-data-xs p,
.nodataTablePosition p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1E2524;
  display: flex;
}

.form-group .downloadBtnBox {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 51px;
}

.form-group .downloadBtnBox .form-group {
  margin: 0;
}

.form-group .downloadBtnBox .form-control,
.form-group .downloadBtnBox .form-control:focus {
  border: 0;
  outline: 0 !important;
  box-shadow: unset !important;
}

.form-group .downloadBtnBox label.chooseBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #00ACB9;
  border: 0.5px solid #00ACB9 !important;
  border-radius: 4px;
  padding: 3px 10px !important;
  margin-bottom: 0 !important;
}

.form-group .downloadBtnBox label.uploading {
  background-color: grey !important;
  color: #fff;
}

.form-group .downloadBtnBox span.downloadBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #00ACB9;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 0.5px solid #00ACB9;
  border-radius: 4px;
  padding: 0 8.8px;
  margin-left: auto;
  width: 101px;
  min-width: 101px;
}

.profileForm .downloadBtnBox span.downloadBtn:hover {
  background: #00ACB9;
  color: #fff;
  justify-content: space-between;
}

.profileForm .downloadBtnBox span.downloadBtn:hover .IconGreen {
  display: none;
}

.profileForm .downloadBtnBox span.downloadBtn .IconWhite{
  display: none;
}

.profileForm .downloadBtnBox span.downloadBtn:hover .IconWhite{
  display: block;
}

.productFormBox.help.tutorials .videoCard p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  /* margin-bottom: 30px; */
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
  margin-bottom: 13px;
  min-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.productFormBox.help.tutorials .videoCard p span.read-or-hide {
  cursor: pointer;
}

.verify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verify .form-group {
  width: 92%;
  margin: 0 !important;
}

.companyDeatilContactNum .css-11d2qd3-container {
  background: #ebebeb;
  border-radius: 6px;
  max-width: 75px;
  min-width: 75px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.form-group .companyDeatilContactNum {
  padding-left: 2px;
}

.form-group .companyDeatilContactNum .form-control {
  padding-left: 80px !important;
}

.form-group .companyDeatilContactNum .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.companyDeatilContactNum::before {
  display: none;
}

.count {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  background: #00ACB9;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.notification_aside .dropdown-menu {
  display: block !important;
  position: unset !important;
}

.custom-popover {
  background-color: transparent !important;
  border: 0 !important;
}

.readNotify {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 2px;
  background: #00ACB9;
  content: '';
  border-radius: 50%;
  z-index: 999999999;
}

img.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.logout-cancel {
  border: 1px solid #E9F1F0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  padding: 15px 65px;
  border-radius: 10px;
  background: transparent;
}


.logout-btn {
  background: #00ACB9;
  box-shadow: 0px 5px 5px rgba(0, 172, 185, 0.08);
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  padding: 15px 60px;
  outline: 0;
  border: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn.arrowBtn.disabled,
.btn.arrowBtn:disabled {
  background-color: transparent !important;
  color: #000000 !important;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .cancelBtn {
  border: 1px solid #E9F1F0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #262626;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  background: transparent;
  margin-top: 20px;
  margin-right: 10px;
}

.verifyidentity_box.otp .resendBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin-top: 8px;
  color: #00ACB9;
  border: 0 !important;
  background-color: transparent !important;
}

.downloadBtnBox p.noFile {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin: 0 0 0 12px;
}



.verifyidentity_box.otp .resendBtngrey {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin-top: 8px;
  color: #667085;
  border: 0 !important;
  background-color: transparent !important;
}

section.formLeftSection form a.customSubmitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
}

.accordion-button.collapsed .showAccordion {
  display: block;
}

.accordion-button .showAccordion {
  display: none;
}

.accordion-button.collapsed .hideAccordion {
  display: none;
}

.accordion-button .hideAccordion {
  display: block;
}

.googleAuthenticator .googleNote {
  display: flex;
  background: #EFF5F5;
  border: 1px solid #E9F1F0;
  border-radius: 12px;
  align-items: center;
  /* width: 51%; */
  padding: 14px 20px;
}

.googleAuthenticator .googleNote p {
  margin: 0;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  opacity: 0.8;
}

.googleAuthenticator .googleNote p a {
  color: #1E2524;
  font-weight: 800;
  font-style: italic;
  text-decoration: underline;
}

.profileMainBox .trash_option .verifyIcon {
  display: none;

}

.profileMainBox .trash_option .position-relative:hover .verifyIcon {
  display: block;
  border: 0;
  background: transparent;
  width: 50px;
  height: 48px;
  padding: 0;
}

.divDisable {
  pointer-events: none;
}


.SocialMediaCard .SocialloginDetails button.trashBtn[disabled] {
  background-color: transparent !important;
  color: #000000 !important;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .img img.icon {
  width: auto;
  /* height: 45px; */
}

.notification_aside .dropdown-menu .scroll_box .notify_row .img img.logo {
  width: 54px;
  height: auto;
}

@media (max-height: 610px) {
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs {
    position: static;
    left: unset;
    top: unset;
    transform: unset;
  }

  .tableCard table.table tbody tr.no-tr td.image-no-data-xs img {
    max-width: 100px;
  }
}

@media (max-width: 1199px) {

  .formLeftSection .glassEffects form button.btn.customSubmitBtn,
  section.formLeftSection form .form-group .form-control {
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs {
    padding-top: 60px;
  }

  .tableCard table.table tbody tr.no-tr td.image-no-data-xs img {
    width: 100px;
  }
}

@media (min-width: 992px) {
  section.formLeftSection .glassEffects {
    padding: 123px 0px !important;
  }

  section.formLeftSection .glassEffects.nextonboarding {
    padding: 186px 0px !important;
  }

}

@media (min-width:992px) and (max-width:1199px) {
  section.formLeftSection .glassEffects {
    padding: 40px 0px !important;
  }
}

@media (min-width:1200px) and (max-width:1500px) {
  section.formLeftSection .glassEffects {
    padding: 40px 0px !important;
  }
}

@media (max-width: 991px) {
  section.formLeftSection form {
    padding-right: 0;
  }

  section.formLeftSection .glassEffects {
    padding: 75px 0px !important;
  }

  section.formLeftSection .logo {
    width: auto;
    max-width: 200px;
  }

  .slick-slide img.cards {
    width: 80%;
  }
}

@media (max-width: 993px) {
  .tableFilter .filter_holder {
    margin-top: 1em;
  }

  .tableFilter .filter_holder div:first-child {
    margin-left: 0 !important;
  }

  .tableFilter .filter_holder .customDropdownBox button {
    margin-left: 0 !important;
  }

  .productFormBox {
    margin-bottom: 2em !important;
  }
}

@media (max-width: 767px) {

  /* nav.navbar.navbar-expand.navbar-light{
    display: none;
  } */
  .tableFilter h3 {
    margin: 0px 0 30px;
  }

  .tableFilter {
    margin-top: 15px;
  }

  .container-fluid h1.h3-head-mob.d-md-none {
    margin: 0;
    padding-top: 25px;
  }

  .billings .billingInviceCard .CardHeader {
    display: block;
  }

  .billings .billingInviceCard .CardHeader h4 {
    margin-bottom: 10px;
  }

  .billings .billingInviceCard .CardHeader .rightSection {
    justify-content: space-evenly;
  }

  .billings .billingInviceCard .CardHeader .switch {
    width: 59px;
  }

}

@media (max-width: 440px) {
  section.formLeftSection .buttonSetPassword .customCancelBtn {
    margin-top: 1em !important;
  }

  .billings .billingInviceCard .CardHeader .rightSection {
    display: block;
    text-align: center;
  }

  .billings .billingInviceCard .CardHeader .rightSection .switchHolder {
    margin-bottom: 10px;
  }

  .productFormBox.reportBatches.billing {
    padding: 13px !important;
  }

  .slick-slide img.cards {
    width: 70%;
  }
}

@media (max-width: 767px) and (min-width: 499px) {

  .formLeftSection .glassEffects form button.btn.customSubmitBtn,
  section.formLeftSection form .form-group .form-control {
    min-width: 400px;
  }


}

@media screen and (max-width:460px) {
  .sliderHolder .cardHolder .slider_inner .card-back {
    padding-bottom: 110px !important;
    width: 275px !important;
  }

  .sliderHolder .cardHolder .slider_inner .card-back .footer .detailHolder p.accountNo {
    font-size: 14px;
    margin: 0;
  }


  .sliderHolder .cardHolder .slider_inner .card-back .footer .detailHolder p.name {
    font-size: 12px;
    margin: 0;
  }
}