/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

*,
body {
  font-family: Inter, sans-serif !important;
}

i.fa {
  font-family: FontAwesome !important;
}

:root {
  --primaryBg: #00acb9;
  --secondaryBg: #e5fdff;

  --primaryColor: #0b0448;
  --secondaryColor: #837b8e;
  --tertiaryColor: #6f6a88;
}

#wrapper {
  display: flex;
  background: #fbfcfc;
  /* background: #F5F5F5; */
  /* background-image: url('../img/BACKGROUND.svg'); */
  /* background: hsla(199, 53%, 94%, 1);
    background: linear-gradient(130deg, hsla(199, 53%, 94%, 1) 0%, hsla(228, 24%, 96%, 1) 50%, hsla(33, 100%, 94%, 1) 100%);
    background: -moz-linear-gradient(130deg, hsla(199, 53%, 94%, 1) 0%, hsla(228, 24%, 96%, 1) 50%, hsla(33, 100%, 94%, 1) 100%);
    background: -webkit-linear-gradient(130deg, hsla(199, 53%, 94%, 1) 0%, hsla(228, 24%, 96%, 1) 50%, hsla(33, 100%, 94%, 1) 100%); */
  /* height: 100vh; */
}

#wrapper #content-wrapper {
  /* background-color: #f2f2f2; */
  width: 100%;
  /* overflow-x: hidden; */
  /* height: 100vh; */
  /* margin-left: 18rem; */
  padding-left: 293px;
  /* margin-top: 32px; */
  padding-top: 32px;
  margin-right: 15px;
  /* height: calc(100vh - 15px); */
  height: calc(100vh - 0px);
  /* overflow: hidden; */
  overflow-y: auto;
}

#wrapper #sidebar {
  height: calc(100vh - 0px);
  width: 265px;
  position: fixed;
  background: #fff;
  border: 1px solid #ffffff;
  border-radius: 0;
  margin-left: 0;
  margin-top: 0;
  margin-right: 0;
  padding: 20px 17px;
  overflow: auto;
  padding-top: 40px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  z-index: 999;
}

/* section.formLeftSection::before{
    content: "";
    background: #fdf1de;
    filter: blur(1600px);
    width: 500px;
    height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
} */

/* section.formLeftSection::after{
    content: "";
    background: rgb(184 227 251 / 50%);
    filter: blur(1600px);
    width: 500px;
    height: 500px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
} */

section.formLeftSection {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-image: url('../img/BACKGROUND.svg'); */
  background-size: cover;
  background-position: center;
  width: 100%;
  background: #fbfcfc;
}

section.formLeftSection .glassEffects {
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 33px;
  padding: 0px;
  margin: 0 0px;
}

section.formLeftSection .glassEffects.py-105 {
  padding: 115px 85px;
}

section.formLeftSection .logo {
  margin-bottom: 63px;
  width: 300px;
}

section.formLeftSection h2 {
  color: #1e2524;
  letter-spacing: 0.4px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 22px;
}

section.formLeftSection p {
  opacity: 0.75;
  color: #667085;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

section.formLeftSection form {
  margin-top: 51px;
  padding-right: 107px;
}

section.formLeftSection form .form-group .form-control,
.loginforgorpassOpt {
  min-width: 400px;
}

form .form-control,
.addVideoFormInput {
  background: linear-gradient(124.48deg, #fcfcfc 7.91%, #f3fcff 124.02%);
  border: 1px solid #ffffff;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 3%);
  border-radius: 14px;
  /* color: #A3A3A3; */
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  padding-left: 28px;
  outline: 0;
}

.form-control:focus {
  background: linear-gradient(
    124.48deg,
    #fcfcfc 7.91%,
    #f3fcff 124.02%
  ) !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.addVideoFormInput:focus {
  background: linear-gradient(
    89.93deg,
    rgba(239, 245, 245, 0.352) -13.9%,
    rgba(255, 255, 255, 0.8) 20.68%,
    rgba(254, 254, 254, 0.8) 80.83%,
    rgba(239, 245, 245, 0.36) 118.86%
  ) !important;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 0px 2px #00acb9 !important;
  transition: 0.01s;
}

.addVideoFormInput::-webkit-input-placeholder {
  font-size: 14px;
}
.addVideoFormInput:-ms-input-placeholder {
  font-size: 14px;
}
.addVideoFormInput::placeholder {
  font-size: 14px;
}

form .form-control-label {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #8b8b8b;
}

form .form-group .a-link {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #8b8b8b;
  text-decoration: underline;
}

form .form-control .css-1pahdxg-control {
  outline: 0 !important;
  border: 0 !important;
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
}

form .form-control .css-2613qy-menu {
  /* font-family: 'Poppins'; */
}

.css-1pahdxg-control:focus,
.css-1pahdxg-control:focus {
  border: none !important;
}

form .form-control.p-0.css-b62m3t-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* font-family: 'Poppins'; */
}

form .form-control.p-0.css-b62m3t-container:focus {
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

form .form-control .css-1s2u09g-control,
form .form-control .css-1insrsq-control {
  height: 45px;
  border: 0;
  background: transparent;
  width: 100%;
  padding-left: 8px;
}

form .form-control .css-1s2u09g-control{
  padding: 0;
  margin-top: -5px;
  margin-left: -2px;
}

form .form-control .css-319lph-ValueContainer{
  padding: 0 0 0 15px;
}

form .form-control .css-319lph-ValueContainer .css-qe9pfn-singleValue{
  font-size: 13px!important;
}

form .form-control .css-6j8wv5-Input{
  margin: 0;
  padding: 0;
}

.viewStaff_page form .readonlyFeild {
  position: relative;
}

.viewStaff_page form .readonlyFeild::before {
  content: "";
  background: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.viewStaff_page form .readonlyFeild .form-control:focus {
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%) !important;
  border: 1px solid #e7e7e7 !important;
}

.productFormBox.viewStaff_page form .form-control.css-b62m3t-container {
  position: relative;
}

.productFormBox.viewStaff_page
  form
  .form-control.css-b62m3t-container
  .css-1s2u09g-control {
  margin-top: -9px;
  outline: 0 !important;
  border: 0 !important;
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
}

.productFormBox.viewStaff_page
  form
  .form-control.css-b62m3t-container
  .css-1pahdxg-control {
  margin-top: -7px;
  outline: 0 !important;
  border: 0 !important;
  width: 100% !important;
  background: transparent !important;
  box-shadow: none !important;
}

.invitestaffmodal .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invitestaffmodal .modal-content {
  background: #ffffff !important;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%) !important;
  border-radius: 12px !important;
  border: 0;
}

.invitestaffmodal .modal-dialog .modal-body {
  padding: 27px 32px 32px 32px;
}
.invitestaffmodal .modal-dialog .modal-body .form-control-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #6f8381;
}
.invitestaffmodal .modal-dialog .modal-body .form-control {
  height: 40px;
  font-size: 14px;
  padding-left: 11px;
}

.invitestaffmodal
  .modal-dialog
  .modal-body
  .col-md-6:nth-child(1)
  .form-group.mb-20px,
.invitestaffmodal
  .modal-dialog
  .modal-body
  .col-md-6:nth-child(2)
  .form-group.mb-20px {
  margin-bottom: 11px;
}

.invitestaffmodal .modal-dialog .modal-body select.form-control.form-select {
  -webkit-appearance: none;
  background-image: url("../img/arrowSelect.svg");
  background-repeat: no-repeat;
  background-position: 94%;
  background-position-y: 51%;
}

.invitestaffmodal .modal-dialog {
  max-width: 494px;
}

.invitestaffmodal button {
  max-height: 40px;
  outline: 0;
  border: 0;
}

.invitestaffmodal .customCancelBtn {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

form .form-control .css-mrvwih-singleValue {
  font-size: 14px;
  /* font-family: 'Poppins'; */
}

form .form-control .css-14el2xx-placeholder {
  line-height: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.mb-25px {
  margin-bottom: 25px !important;
}

section.formLeftSection form a,
.form-check .form-check-label {
  color: var(--primaryBg);
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

form .customSubmitBtn {
  background: var(--primaryBg);
  box-shadow: 0px 5px 5px rgb(0 172 185 / 8%);
  border-radius: 10px;
  height: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
  letter-spacing: 0.05em;
}

form .customSubmitBtn:hover {
  color: #ffffff;
}

section.formLeftSection .buttonSetPassword {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

section.formLeftSection .customCancelBtn {
  background: #eff5f5;
  border-radius: 12px;
  box-shadow: none;
  border: 0;
  filter: none;
  font-size: 18px;
  font-weight: 600;
  color: #344054;
}

form .customCancelBtn {
  height: 45px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 14px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.05em;
  color: #464255;
  opacity: 0.75;
  width: 100%;
  margin-top: 49px;
  border: 2px solid #c4e6fc;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05));
  margin-right: 22px;
  cursor: pointer;
}

section.formLeftSection .custom-control.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

section.formLeftSection .custom-control-label {
  color: #a3a3a3;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  padding-left: 7px;
}

section.formLeftSection .custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0;
  border: 1px solid #cdcdcd;
  border-radius: 5px !important;
  background: #ffffff;
}

section.formLeftSection .custom-control-label::after {
  width: 20px;
  height: 20px;
  top: 0;
}

section.formLeftSection .custom-control-input {
  width: 20px;
  height: 20px;
  top: 0;
}

section.formLeftSection
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primaryBg);
  background-color: var(--primaryBg);
}

section.formLeftSection
  .custom-control-input:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(15 81 161 / 15%);
}

section.formLeftSection
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #1455a3a8;
}

.formLeftSection .glassEffects form label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin-bottom: 8px;
  display: block;
}

.formLeftSection .glassEffects form button.btn.customSubmitBtn {
  margin-top: 37px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 100%;
}

.formLeftSection .glassEffects form .form-group {
  margin-bottom: 25px;
}

.mr-10px {
  margin-right: 10px;
}

.socialLogin .myshadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
}

.socialLogin div a {
  transition: 0.1s ease-in-out;
  display: inline-block;
}

.socialLogin div a:hover {
  transform: translateY(-5px);
}

.socialLogin h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1e2524;
  margin: 0;
}

.text-danger {
  font-size: 14px;
}

.sidebarOptions {
  padding: 0;
  list-style: none;
  margin-top: 37px;
  overflow-y: auto;
  height: 75vh;
  margin-bottom: 0;
}
.sidebarOptions li {
  margin-bottom: 4px;
}
.sidebarOptions li a {
  background: transparent;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #6f8381;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.sidebarOptions li span span {
  background: transparent;
  border-radius: 14px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.4px;
  color: #6f8381;
  padding: 0;
  position: relative;
  overflow: hidden;
  letter-spacing: 0px;
}

.sidebarOptions li span.nav-link.sub-link {
  display: flex;
  padding: 0px 14px;
  align-items: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.sidebarOptions li span.nav-link.sub-link img {
  width: 20px;
  margin-right: 12px;
}

.sidebarOptions li span.nav-link.sub-link img.arr-icon-side {
  position: absolute;
  right: 15px;
  margin: 0;
  width: auto;
}

.sidebarOptions li.nav-item.active-show a.nav-link,
.sidebarOptions li.nav-item.active-show span.nav-link.sub-link {
  background: var(--secondaryBg);
  color: var(--primaryBg);
}

.sidebarOptions li.nav-item.active-show span.nav-link.sub-link span {
  color: var(--primaryBg);
}

.sidebarOptions li.nav-item.active-show a.nav-link::before,
.sidebarOptions li.nav-item.active-show span.nav-link::before {
  content: "";
  /* background-image: url('../img/sidebarActiveLine.svg');
    position: absolute;
    width: 100%;
    height: 3px;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: -2px; */
}

.sidebarOptions li .collapse .collapse-inner,
.sidebarOptions li .collapsing .collapse-inner {
  padding-left: 2.5em;
}

.sidebarOptions li .collapse .collapse-inner a,
.sidebarOptions li .collapsing .collapse-inner a {
  text-decoration: none;
  height: 34px;
  padding: 0px 17px;
  font-weight: 400;
  color: #6f8381;
}

.sidebarOptions li a.nav-link.sub-link img {
  width: 20px;
  margin-right: 12px;
}

.sidebarOptions li a.active::before {
  content: "";
  /* background-image: url('../img/sidebarActiveLine.svg');
    position: absolute;
    width: 100%;
    height: 3px;
    transform: translate(-50%, -50%);
    left: 50%;
    bottom: -2px; */
}

.sidebarOptions li.nav-item .collapse .collapse-inner a.collapse-item.active {
  background: var(--secondaryBg);
  color: var(--primaryBg) !important;
  border-left: 2px solid var(--primaryBg) !important;
  border-radius: 0px 8px 8px 0px;
}

.sidebarOptions li a .optionIcon {
  width: 20px;
  margin-right: 18px;
}

.sidebarOptions li a:hover,
.sidebarOptions li span.nav-link.sub-link:hover,
.sidebarOptions li span.nav-link.sub-link:hover span {
  color: #6f8381;
  background: #f6f6f9;
  cursor: pointer;
}

.sidebarOptions li.nav-item div .collapse-inner a.collapse-item {
  border-left: 2px solid transparent;
}

.sidebarOptions li.nav-item div .collapse-inner a.collapse-item:hover {
  background: #f9f9f9;
  border-left: 2px solid #d1d4db;
  border-radius: 0px 8px 8px 0px;
}

.sidebarOptions li a.nav-link {
  padding: 0 14px;
  position: relative;
  letter-spacing: 0px;
}

.sidebarFooter {
  background: #f7f6f9;
  border: 1px solid #f0ecf9;
  border-radius: 12px;
  padding: 16px 14px;
  margin-top: 30px;
  position: relative;
}

.sidebarFooter .imgbox {
  border-radius: 50px;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.sidebarFooter .imgbox img {
  width: 40px;
}

.sidebarFooter h3 {
  color: #1e2524;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 7px;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
  width: 130px;
  max-width: 130px;
  overflow-wrap: break-word;
}
.sidebarFooter h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #837b8e;
  margin-bottom: 14px;
}
.sidebarFooter p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #6f6b88;
  margin-bottom: 0;
}

.sidebarFooter::before {
  content: "";
  background: url("../img/rightArrowSidebar.svg");
  width: 8px;
  height: 14px;
  position: absolute;
  right: 11px;
  background-repeat: no-repeat;
  top: 36%;
  transform: translate(-50%, -50%);
}

/* .sidebarOptions li span.nav-link.sub-link:hover span{
    color: #0054FE;
} */

.sidebarOptions li a:focus {
  box-shadow: none;
}

.sidebarOptions li:nth-child(8) {
  margin-bottom: 24px;
}

.sidebarOptions li:nth-child(9) {
  padding-top: 54px;
  position: relative;
}

.sidebarOptions li:nth-child(9)::before {
  content: "";
  width: 100%;
  background: #f4f1f4;
  height: 1px;
  position: absolute;
  top: 0;
}

.sidebarOptions li:nth-child(9)::after {
  content: "Support";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #aebebc;
  position: absolute;
  left: 13px;
  top: 24px;
}

.sidebarOptions li:nth-child(10) {
  margin-bottom: 24px;
}

.sidebarOptions li:nth-child(11) {
  padding-top: 54px;
  position: relative;
}

.sidebarOptions li:nth-child(11)::before {
  content: "";
  width: 100%;
  background: #f4f1f4;
  height: 1px;
  position: absolute;
  top: 0;
}

.sidebarOptions li:nth-child(11)::after {
  content: "More";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #aebebc;
  position: absolute;
  left: 13px;
  top: 24px;
}

.sidebarOptions li:nth-child(12) {
  margin-bottom: 0;
}

/* width */
.sidebarOptions::-webkit-scrollbar {
  width: 0;
  border-radius: 20px;
}

/* Track */
.sidebarOptions::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
.sidebarOptions::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 20px;
}

/* Handle on hover */
.sidebarOptions::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
  border-radius: 20px;
}

table tr th span.cursor-pointer {
  margin-left: 10px;
}

/* manageBatch table manage from here */

.tableCard table.table.manageBatchTable thead th:nth-child(2) {
  min-width: 180px !important;
  max-width: 180px !important;
}

.tableCard table.table.manageBatchTable thead th:nth-child(3) {
  min-width: 170px !important;
  max-width: 170px !important;
}

.tableCard table.table.manageBatchTable thead th:nth-child(4) {
  min-width: 174px !important;
  max-width: 174px !important;
}

.tableCard table.table.manageBatchTable tbody tr td span.textContol {
  max-width: 100px;
}

/* counterfeit table manage from here */

.tableCard table.table.counterfeitTable thead th:nth-child(1) {
  min-width: 176px !important;
  max-width: 176px !important;
}

.tableCard table.table.counterfeitTable thead th:nth-child(2) {
  min-width: 172px !important;
  max-width: 172px !important;
}

.tableCard table.table.counterfeitTable thead th:nth-child(3) {
  min-width: 172px !important;
  max-width: 172px !important;
}

.tableCard table.table.counterfeitTable thead th:nth-child(4) {
  min-width: 156px !important;
  max-width: 156px !important;
}

.tableCard table.table.counterfeitTable tbody tr td span.textContol {
  max-width: 100px;
  min-width: 100px;
}
.tableCard
  table.table.counterfeitTable
  tbody
  tr
  td:nth-child(5)
  span.textContol {
  max-width: fit-content;
  min-width: fit-content;
}

.tableCard table.table tbody tr td span.textContol span {
  /* font-family: 'Poppins'; */
}

.tableCard table.table tbody tr td span span {
  height: 40px;
  width: 165px;
  border-radius: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.tableCard table.table tbody tr td span span.bold-desc.cursor-pointer {
  height: 14px;
  display: inline-block !important;
}

.tableCard table.table tbody tr td span.textContol {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2524;
}

.tableCard table.table tbody tr td .textContol {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #464255;
  display: -webkit-box;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
}

.tableCard table.table tbody tr td {
  position: relative;
}

.tableCard table.table tbody tr:hover td:first-child:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 100%;
  left: 0;
  /* background: #0054FE; */
  top: 0;
}

.tableCard table.table tbody tr:hover td {
  background: #fdfdfd;
}

.tableCard table.table tbody tr:hover {
  /* box-shadow: 0px 15px 15px 0px rgb(0 0 0 / 6%); */
}

.tableCard table.table tbody tr:hover td.image-no-data-sm:first-child:before {
  display: none;
}

.tableCard table.table tbody tr.no-tr:hover {
  box-shadow: none;
}

/* width */
.table-responsive.fixed-height-table::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

/* Track */
.table-responsive.fixed-height-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
.table-responsive.fixed-height-table::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 20px;
}

/* Handle on hover */
.table-responsive.fixed-height-table::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
  border-radius: 20px;
}

.no-background {
  background-image: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8894a8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8894a8;
}

.tableFilter {
  /* margin-top: 35px; */
}

.tableFilter h3 {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1e2524;
  margin: 0;
}

.tableFilter h3 p.breadcrums-top {
  margin: 0;
}

.tableFilter h3 p.breadcrums-top span:last-child {
  color: #837b8e !important;
}

.tableFilter h3 p.breadcrums-top img.breadcrum-seperator {
  width: 8px;
  margin: 0 10px;
}

.tableFilter h3 p.breadcrums-top a {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #464255;
  margin: 0;
}

.tableFilter input[type="search"] {
  background: #eff5f5 !important;
  border: 1px solid #e9f1f0 !important;
  border-radius: 12px !important;
  /* background: #E9EEF9!important;
    border-radius: 14px!important; */
  width: 383px;
  height: 48px;
  padding-left: 45px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  /* opacity: 0.6; */
  background-image: url("../img/search.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position-x: 15px !important;
  background-size: 18px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.tableFilter input[type="search"]:focus {
  box-shadow: none;
  border-color: #dbe4f9;
}

.tableFilter .search_icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30px;
  width: 22px;
}

.no-arrow.dropdown-toggle::after {
  display: none !important;
}

.dropdown-custom.dropdown-toggle,
.customDropdownBox .counterfeitdropdown {
  background: #e9eef9;
  border-radius: 14px !important;
  min-width: 160px;
  height: 45px;
  padding: 15px 25px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  transition: 0.2s;
  border: 0;
}

.dropdown-custom.dropdown-toggle[aria-expanded="true"] img {
  transform: rotate(180deg);
}

.dropdown-toggle img {
  transition: 0.2s;
}

.dropdown-toggle[aria-expanded="true"] img {
  transform: rotate(180deg);
}

.dropdown-custom.dropdown-toggle:focus {
  box-shadow: none;
  border-color: #dbe4f9;
}

.dropdown-custom.dropdown-toggle span.staff-value {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #0054fe;
}

.dropdown-custom.dropdown-toggle img {
  margin-left: 15px;
  transition: 0.2s;
}

.customDropdownBox .dropdown-menu {
  padding: 10px 0;
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0 rgb(0 0 0 / 4%);
}

.dashboardPage .mycard .cardHeader .customDropdownBox .dropdown-menu {
  border: 1px solid #eee;
}

.dashboardPage .mycard.mapcard .cardBody {
  max-height: none;
  overflow: unset;
}

.customDropdownBox .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 25px;
  clear: both;
  color: #8a94a6;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  cursor: pointer;
}
.customDropdownBox .dropdown-menu .dropdown-item:hover {
  background: transparent;
  /* color: #0054fe; */
}

.addProductbtn {
  background: #00acb9;
  border: 1px solid #00acb9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  padding: 13px 17px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
  /* display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none!important;
    background-image: url('../img/addproduct_icon.svg');
    background-repeat: no-repeat;
    background-position: 10px 50%; */
}

.addProductbtn:hover {
  color: #ffffff;
  text-decoration: none;
  transform: translateY(-8px);
}

.customCancelBtn.topheader:hover {
  transform: translateY(-8px);
}

.customEditbtn_viewstaff:hover {
  text-decoration: none;
  transform: translateY(-8px);
}

.addProductbtn.normalbtn {
  background-image: none;
  padding: 0;
}

.chooseFileBtn {
  width: 56px;
  height: 45px;
  border: 2px solid #c4e6fc;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.chooseFileBtn input[type="file"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  background: red;
  height: 50px;
}

.Toastify__toast-body > div:last-child {
  flex: 1 1;
  /* font-family: 'Poppins'; */
  font-weight: 500;
  font-size: 16px;
}

.logoutAlertPopup .swal2-title {
  font-size: 1.125em;
  color: #545454;
  font-weight: 400;
  margin-bottom: 0;
}

.logoutAlertPopup .swal2-actions {
  margin: 1em auto 0;
}

.logoutAlertPopup .swal2-actions button {
  width: 75px;
}

.footer-pg {
  padding-left: 32px;
  padding-top: 8px;
}

.footer-pg .pagination a.leftPageArrow {
  background: #f6faff;
  border: 1px solid #5181bb26;
  transform: rotate(180deg);
}

.footer-pg .pagination a.rightPageArrow {
  background: #f6faff;
  border: 1px solid #5181bb26;
}

.footer-pg .pagination a.rightPageArrow.singleVisit {
  margin-left: 20px;
}
.footer-pg .pagination a.leftPageArrow.singleVisit {
  margin-right: 10px;
  margin-left: 5px;
}

.footer-pg .pagination a.rightPageArrow.lastpage {
  margin-left: 5px;
}

.footer-pg .pagination {
  position: relative;
}

.footer-pg .pagination a .footer-pg .pagination a {
  width: 35px;
  height: 38px;
  border-radius: 8px;
  /* background: #BDD2E7; */
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 0px;
}
.footer-pg .pagination a.active {
  background: #edfdfb;
  border: 1px solid #00acb9;
  border-radius: 8px;
  color: #00acb9 !important;
}

.paginationArrows {
  background: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  margin: 0;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paginationArrows:last-child {
  margin-left: 10px;
}
.paginationArrows img {
}

.tableFooter .float-right .right.mb-2 {
  margin-bottom: 0 !important;
  margin-right: 29px;
}

.tableFooter .totalRecords {
  margin: 0;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #464255;
  margin-right: 200px;
}

.batchTable tr td div button {
  padding: 0;
  display: flex;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #464255;
  border-radius: 14px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.batchTable tr td div button img {
  margin-right: 13px;
}
.batchTable tr td div .editButton {
  background: #f1f9fd;
}
.batchTable tr td div .editButton:hover {
  background: #e9f7ff;
}
.batchTable tr td div .printButton {
  background: #f2fbf8;
}
.batchTable tr td div .printButton:hover {
  background: #eafbf6;
}
.batchTable tr td div .printButton.reopen {
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}
.batchTable tr td div .deleteButton {
  background: #fde4e4;
}

.addeditVideo_btns .printButton {
  height: 40px;
  letter-spacing: 0.05em;
  background: #00acb9 !important;
  box-shadow: 0px 5px 5px rgb(0 172 185 / 8%) !important;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  border: 0;
  box-shadow: none;
  width: 100px;
  opacity: 1;
}

.addeditVideo_btns .deleteBtn {
  height: 40px;
  letter-spacing: 0.05em;
  background: rgba(102, 112, 133, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #262626;
  border: 0;
  box-shadow: none;
  width: 100px;
}

.addeditVideo_btns .deleteBtn:hover {
  /* transform: scale(1.03); */
  background: rgb(102 112 133 / 10%);
}

.addeditVideo_btns .printButton:hover {
  /* transform: scale(1.03); */
  background: #009da9 !important;
  color: #fff;
}

.tableFilter .exportasBtn {
  padding: 0;
  display: flex;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #464255;
  border-radius: 14px;
  width: 112px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f9fd;
  width: 160px;
  outline: none;
  border: 0;
}

.tableFilter .exportasBtn.dropdown-toggle::after {
  display: none !important;
}

.tableFilter .printButton.exportasBtn {
  background: #f2fbf8;
}

.tableFilter .editProbtn.exportasBtn {
  background: #fff5e5;
}

.tableFilter .editProbtn.exportasBtn img {
  width: 18px;
}

.tableFilter .exportasBtn img {
  margin-right: 13px;
}

.batchHistoryTable tr td div .exportasBtn {
  padding: 0;
  display: flex;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #464255;
  border-radius: 14px;
  width: 112px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f9fd;
}

.batchHistoryTable tr td div .exportasBtn.dropdown-toggle::after {
  display: none !important;
}

.batchHistoryTable tr td div button {
  padding: 0;
  display: flex;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #464255;
  border-radius: 14px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.batchHistoryTable tr td div button img {
  margin-right: 13px;
}
.batchHistoryTable tr td div .editButton {
  background: #f1f9fd;
}
.batchHistoryTable tr td div .printButton {
  background: #f2fbf8;
}
.batchHistoryTable tr td div .deleteButton {
  background: #fde4e4;
}

.batchHistoryTable tr td div .markActiveButton {
  display: flex;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #464255;
  border-radius: 14px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  padding: 0 10px;
  background-color: #fff5e5;
}

.react-datepicker {
  font-family: "Poppins" !important;
}

.intableCheckbox .custom-control-label::before {
  width: 20px;
  height: 20px;
}
.intableCheckbox .custom-control-label::after {
  background-size: 11px;
  top: 0.35rem;
  left: -1.4rem;
}

.width-0 {
  width: 0;
}

.customModelClient {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-family: 'Poppins'; */
}
.customModelClient .modal-content {
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
  border: 0;
  width: 416px;
}

.modal {
  background: rgb(0 0 0 / 65%) !important;
}

.customModelClient .modal-content .modal-header {
  padding: 20px 25px;
}
.customModelClient .modal-content .modal-header .modal-title {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1;
  color: #464255;
  margin-bottom: 0;
}
.customModelClient .modal-content .modal-header .btn-close {
  display: none;
}
.customModelClient .modal-body {
  padding: 32px;
}
.customModelClient .modal-body .row .heading-col span {
}
.customModelClient .modal-body .row .heading-col h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #6f8381;
  margin: 0;
  margin-bottom: 8px;
}
.customModelClient .modal-body .row .description-col h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #667085;
}

.customModelClient .modal-body .form-group {
  /* margin-bottom: 0!important; */
}

.customModelClient .modal-body input[type="number"] {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  height: 40px;
  padding-left: 10px;
  width: 100%;
}

.customModelClient .modal-body input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .customModelClient .modal-body input[type="number"]:focus {
  background: linear-gradient(
    124.48deg,
    #fcfcfc 7.91%,
    #f3fcff 124.02%
  ) !important;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 5%) !important;
  border: 1px solid #ede9e9 !important;
} */

.customModelClient .modal-footer button.cancel-btn {
  background: #fff3f3;
  border: 1px solid #f9d1d1;
  border-radius: 14px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #ff6262;
  width: 160px;
  height: 45px;
  text-decoration: none !important;
  margin: 0 5px;
}

.customModelClient .modal-footer button.print-btn {
  background: #e0f9f1;
  border: 1px solid #b1e7d6;
  border-radius: 14px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #19ac94;
  width: 160px;
  height: 45px;
  text-decoration: none !important;
  margin: 0 5px;
}

.customModelClient .modal-footer button:hover {
  opacity: 0.8;
}

/* .textContol span{
    background: rgba(255, 187, 84, 0.15);
    border-radius: 14px;
    width: 165px;
    height: 40px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    color: #FFBB54;
    letter-spacing: 0.4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
} */

.danger-span {
  background: #fde4e4 !important;
  color: #ff5b5b !important;
}

.success-span {
  background: #ddf6ef !important;
  color: #00a389 !important;
}

.warning-span {
  background: #fff5e5 !important;
  color: #ffbb54 !important;
}

.primary-span {
  background: #e5f2ff !important;
  color: #007bff !important;
}

.secondary-span {
  background: #f3f4f6 !important;
  color: #8a94a6 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.customDropdownBox .dropdown-toggle::after {
  display: none;
}

.customDropdownVideo.customDropdownBox .dropdown-menu {
  transform: translate3d(-130px, 16.5714px, 0px) !important;
}

.customDropdownVideo.customDropdownBox.manageVideoSelect .dropdown-menu {
  transform: unset !important;
}

.productFormBox form .form-group .imageBoxView {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%);
  border-radius: 10px;
  height: 140px;
  padding: 10px 0;
}

.counterfeitModeltwo .modal-dialog .modal-body h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1e2524;
  margin-bottom: 12px;
}
.counterfeitModeltwo .modal-dialog .modal-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(30, 37, 36, 0.6);
  padding-bottom: 10px;
  margin: 0 0 57px 0;
}

.counterfeitModeltwo .modal-dialog {
  min-width: 637px;
}
.counterfeitModeltwo .modal-content {
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
  border: 4px solid #ffffff;
  padding: 32px;
}

.counterfeitModeltwo .modal-content .modal-body {
  padding: 0;
}

.counterfeitModeltwo .modal-content .modal-body textarea {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%);
  border-radius: 10px;
  width: 100%;
  margin-top: 30px;
  height: 130px;
  resize: none;
  outline: 0;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  padding: 15px 25px;
}

.counterfeitModeltwo .modal-content .modal-body form .customCancelBtn {
  margin-right: 27px;
}

.counterfeitModeltwo .modal-content .checkModalBox input[type="radio"] {
  transform: scale(1.3);
}
.counterfeitModeltwo .modal-content .checkModalBox span {
  color: #1e2524;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  margin-left: 0;
}

.counterfeitModeltwo .modal-content .checkModalBox {
  margin-top: 25px;
}

/* The container */
.counterfeitModeltwo .modal-content .container {
  display: block;
  position: relative;
  padding-left: 37px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.counterfeitModeltwo .modal-content .container div {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
}

/* Hide the browser's default radio button */
.counterfeitModeltwo .modal-content .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Create a custom radio button */
.counterfeitModeltwo .modal-content .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #00acb9;
}

/* On mouse-over, add a grey background color */
.counterfeitModeltwo .modal-content .container:hover input ~ .checkmark {
  background-color: #00acb9;
  opacity: 0.2;
}

.counterfeitModeltwo
  .modal-content
  .container:hover
  input:checked
  ~ .checkmark {
  opacity: 1;
}

/* When the radio button is checked, add a blue background */
.counterfeitModeltwo .modal-content .container input:checked ~ .checkmark {
  background-color: #00acb9;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.counterfeitModeltwo .modal-content .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.counterfeitModeltwo
  .modal-content
  .container
  input:checked
  ~ .checkmark:after {
  display: block;
}

/* .modal.counterfeitModeltwo{
  pointer-events: none;
} */

.counterfeitModeltwo .modal-dialog {
  pointer-events: all;
}

/* Style the indicator (dot/circle) */
.counterfeitModeltwo .modal-content .container .checkmark:after {
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #00acb9;
  border: 3px solid #fff;
  transform: translate(-50%, -50%);
}

.counterfeitModeltwo.popuptwo .modal-dialog .modal-body h3 {
  margin-bottom: 19px;
}
.counterfeitModeltwo.popuptwo .modal-dialog .modal-body p {
  padding-bottom: 25px;
  margin: 0;
  /* border-bottom: 1px solid #F1F4F3; */
}

.counterfeitModeltwo.popuptwo .modal-dialog .modal-body textarea {
  margin-top: 25px;
}

.error-text-video {
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  padding-left: 0;
  margin-top: 5px;
}

.col-md-12.col-vd-nodata {
  text-align: center;
}

.productFormBox .prodductItems {
  width: 70px;
  height: 70px;
  border-radius: 14px;
  background: #fff;
  padding: 10px;
}

.productFormBox .prodductItems img {
  width: 100%;
}

.bgLoader .loaderBox .purpleBox {
  background: var(--primaryBg);
}

.image-loader {
  max-width: 493px !important;
  width: 493px !important;
}

.react-datepicker__header {
  background-color: var(--primaryBg) !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primaryBg) !important;
}

.react-datepicker__header .react-datepicker__current-month,
.react-datepicker__header .react-datepicker-time__header,
.react-datepicker__header .react-datepicker-year-header,
.react-datepicker__header .react-datepicker__day-name,
.react-datepicker__header .react-datepicker__day,
.react-datepicker__header .react-datepicker__time-name {
  color: #fff !important;
}

.invite_section .formCard .formStepsBox .react-datepicker__header .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select select{
  color: rgb(0 0 0 / 100%);
}

.react-datepicker .react-datepicker__day--selected {
  background-color: #0f51a1;
}

.react-datepicker__navigation-icon::before {
  border-color: #fff !important;
}

.react-datepicker__navigation:hover *::before {
  border-color: #e0e0e0 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--primaryBg);
}

.react-datepicker__header .react-datepicker__day-name {
  font-weight: 500;
}

.react-datepicker__header
  .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select
  select {
  background: rgb(255 255 255);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(102, 112, 133, 0.8);
  height: 25px;
  padding-left: 3px;
}

#wrapper #sidebar.toggled {
  visibility: visible;
  opacity: 1;
}

.staffGrid {
  background: #ffffff;
  border: 1px solid #eff5f5;
  border-radius: 12px;
  padding: 40px 25px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: 0.25s;
}

.staffGrid:hover {
  transform: scale(1.03);
  -webkit-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 7%);
}

.staffGrid .userPic img {
  width: 60px;
}

.staffGrid .userPic {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.staffGrid h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #00acb9;
  margin-top: 12px;
  margin-bottom: 12px;
}

.staffGrid .card_id {
  background: #eff5f5;
  border-radius: 8px;
  width: 148px;
  height: 38px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
}

.staffGrid .contact_info {
  margin-bottom: 48px;
}
.staffGrid .contact_info .imgbox {
  margin-right: 20px;
  display: flex;
}
.staffGrid .contact_info a,
.staffGrid .contact_info p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #344054;
  text-decoration: none;
  margin: 0;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 151px;
}

.staffGrid .mb-20px {
  margin-bottom: 20px;
}

.staffGrid .mb-20px:last-child {
  margin-bottom: 0;
}

.staffGrid .buttonsBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.staffGrid .buttonsBox .view_btn {
  background: #eff5f5;
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
  min-width: 91px;
  height: 34px;
}
.staffGrid .buttonsBox .contact_btn {
  background: #00acb9;
  border-radius: 8px;
  outline: 0;
  border: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  min-width: 91px;
  height: 34px;
}

.staffGrid .buttonsBox button:first-child {
  margin-right: 15px;
}

.staffGrid .buttonsBox img {
  margin-right: 6px;
}

.staffGrid .newCorner {
  background: #00acb9;
  border-radius: 14px;
  transform: rotate(-45deg);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  width: 200px;
  height: 61px;
  position: absolute;
  top: -5px;
  left: -81px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
}

.staffTableNew .nameBox_stafflist {
  display: flex;
  align-items: center;
}

.staffTableNew .nameBox_stafflist div {
  border-radius: 50px;
  width: 36px;
  height: 36px;
  overflow: hidden;
}

.staffTableNew .nameBox_stafflist img.list-view-img-staff {
  margin-right: 6px;
  width: 100%;
}

.staffTableNew .nameBox_stafflist p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  text-align: left;
  margin-left: 12px;
}

.tableCard .staffTableNew tr:hover span.staffStatus,
.tableCard .staffTableNew tr:hover .contactButtonNew,
.tableCard .staffTableNew tr:hover button.printButton.reopen {
  background: #ffffff !important;
  border: 1px solid #99a4a3 !important;
}

.staffTableNew tr td a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2524;
  text-decoration: none;
}

.tableCard .staffTableNew tr td span.staffStatus {
  background: #eff5f5 !important;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  min-width: 100px !important;
  max-width: 100px !important;
  height: 40px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableCard .staffTableNew tr td span.staffStatus.success-span {
  color: #246a18 !important;
}

.tableCard .staffTableNew tr td span.staffStatus.warning-span {
  color: #f49e1d !important;
}

.tableCard .staffTableNew tr td span.staffStatus.danger-span {
  color: rgba(198, 15, 15, 0.7) !important;
}

.tableCard .staffTableNew tr td span.staffStatus.secondary-span {
  color: #344054 !important;
}

/* invited staff table */

.tableCard .staffTableNew tr td span.staffStatus.accepted-span {
  color: #246a18 !important;
}

.tableCard .staffTableNew tr td span.staffStatus.rejected-span {
  color: #f49e1d !important;
}

.tableCard .staffTableNew tr td span.staffStatus.cancelled-span {
  color: rgba(198, 15, 15, 0.7) !important;
}

.tableCard .staffTableNew tr td span.staffStatus.invited-span {
  color: #344054 !important;
}

.tableCard .staffTableNew tr td span.staffStatus.pending-span {
  color: #344054 !important;
}

.tableCard .staffTableNew tr td .editButtonNew {
  background: #00acb9;
  border-radius: 10px;
  width: 87px;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin-right: 8px;
}

.tableCard .staffTableNew tr td .contactButtonNew {
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  width: 105px;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}

.tableCard .staffTableNew tr td .editButtonNew img,
.tableCard .staffTableNew tr td .contactButtonNew img {
  margin-right: 8px !important;
}

.tableCard .staffTableNew tr td .editButtonNew:hover {
  background-color: #00a1ad;
}

.tableCard .staffTableNew.invitedStaffList tr td .printButton img {
  margin-right: 8px;
}

.customEditbtn_viewstaff {
  background: #ffffff;
  border: 1px solid #99a4a3;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 12px;
  width: 90px;
  height: 48px;
  margin-left: 16px;
  transition: 0.3s !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customEditbtn_viewstaff img {
  margin-right: 8px;
}

.viewStaff_page h4.form-head {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1e2524;
  margin: 0 0 21px;
}

.viewStaff_page .img-col-staff {
  display: inline-block;
}
.viewStaff_page .img-col-staff .user-img {
  width: 150px;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
}

.viewStaff_page .st-name-head.center {
  text-align: center;
  margin-top: 10px;
}
.viewStaff_page .st-name-head.center .sf_name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1e2524;
  margin-bottom: 35px;
}
.viewStaff_page .col-md-3.col-img-sf .form-group.mb-20px {
  margin-bottom: 25px;
}

.viewStaff_page .col-md-3.col-img-sf .input-group-prepend .switch .slider {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  overflow: hidden;
}

.viewStaff_page .col-md-3.col-img-sf .input-group-prepend .switch .slider .btn {
  width: 90px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewStaff_page
  .col-md-3.col-img-sf
  .input-group-prepend
  .switch
  .slider
  .btn.active {
  background: #00acb9;
  color: #ffffff;
}

.viewStaff_page
  .col-md-3.col-img-sf
  .input-group-prepend
  .switch
  .slider
  .btn:focus {
  outline: 0;
  box-shadow: none;
}

.viewStaff_page form .form-control {
  height: 40px;
}

.productFormBox.viewStaff_page {
  padding: 32px 33px 50px 59px !important;
  margin-bottom: 6em;
}

.productFormBox.viewStaff_page .img-col-staff {
  margin-top: 0 !important;
}

.viewStaff_page .col-md-3.col-img-sf .form-group .form-control {
  width: 180px;
}

.productFormBox.viewStaff_page .col-md-3.col-img-sf {
  position: relative;
}
.productFormBox.viewStaff_page .col-md-3.col-img-sf::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background: #e7e7e7;
  right: 13px;
  top: 0;
}

.viewStaff_page .col-md-3.col-img-sf .file-upload-btn-sf .file-label {
  width: 88px;
  height: 40px;
  background: #00acb9;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  border: 0;
  margin-right: 0;
}

.dashboardPage .mycard {
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%),
    0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
  padding: 33px 32px;
}

.dashboardPage .mycard .cardHeader {
  padding: 32px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f1f4f3;
}

.dashboardPage .mycard .cardHeader button.dropdown-toggle {
  padding: 0;
}

.dashboardPage .mycard .cardHeader .customDropdownBox.dropdown {
  height: 15px;
  display: flex;
}

.dashboardPage .mycard.calendarCustom .cardBody {
  overflow: unset;
  max-height: 409px;
  min-height: 409px;
}

.dashboardPage .mycard .cardBody {
  padding: 25px 0 13px;
  min-height: 350px;
  overflow: hidden;
  max-height: 350px;
}
.dashboardPage .mycard .cardBody h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #1d2524;
  margin-bottom: 4px;
}
.dashboardPage .mycard .cardBody h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1d2524;
}

.dashboardPage .mycard .cardHeader h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1d2524;
  margin: 0;
}

.dashboardPage .mycard .cardHeader span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
}

.dashboardPage .mycard.minicards {
  padding: 38px 32px;
}

.dashboardPage .mycard .valueBox {
  margin-left: 25px;
}

.dashboardPage .mycard .valueBox h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1d2524;
  margin-bottom: 5px;
}
.dashboardPage .mycard .valueBox p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin: 0;
}

.dashboardPage .mycard .graphBox span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 11px;
}

.dashboardPage .mycard .graphBox span.successText {
  color: #246a18;
}

.dashboardPage .mycard .graphBox span.dangerText {
  color: rgba(198, 15, 15, 0.7);
}

.dashboardPage .mycard .cardBody .scrollBox {
  overflow: auto;
  height: 280px;
  padding-right: 5px;
}

.dashboardPage .mycard .cardBody .scrollBox ul {
  padding: 0;
  list-style: none;
  margin: 0 8px 0 0;
}

.dashboardPage .mycard .cardBody .scrollBox ul li {
  /* font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: #1D2524; */
  border-bottom: 1px solid #f1f4f3;
  padding: 12px 0;
  line-height: 1;
}

.dashboardPage .mycard .cardBody .scrollBox ul li a.notifyLink {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.17px;
  color: #1d2524;
  border-bottom: none;
  padding: 0;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map {
  overflow: auto;
  height: 410px;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map ul {
  padding: 0;
  list-style: none;
  margin: 0 0 0 11px;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map ul li {
  margin-bottom: 17px;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map ul li.active .imgBox {
  border: 2px solid #00acb9;
  border-radius: 50px;
  padding: 2px;
}

.dashboardPage
  .mycard.mapcard
  .cardBody
  .scrollBox_map
  ul
  li.active
  .imgBox
  img {
  width: 26px;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map ul li p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #1d2524;
  padding-left: 30px;
}

.dashboardPage .mycard.mapcard .cardBody .scrollBox_map ul li h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #667085;
}

.dashboard_recentscan {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1d2524;
  margin: 0;
}

.upcomingEvent .cardBody .customCard {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 10px;
  padding: 20px 16px;
  margin-bottom: 15px;
  cursor: pointer;
}

.upcomingEvent .cardBody .customCard h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: #1d2524;
  margin-bottom: 6px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 65%;
}

.upcomingEvent .cardBody .customCard h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: rgba(198, 15, 15, 0.7);
}
.upcomingEvent .cardBody .customCard p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.17px;
  color: #667085;
  margin: 0;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.staffListingui .col-vd-nodata {
  margin: 5em 0;
}

.staffListingui .col-vd-nodata img {
  max-width: 120px;
}

.staffListingui .col-vd-nodata p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
}

td.text-center.image-no-data-sm img {
  max-width: 120px;
}
td.text-center.image-no-data-sm p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
}

.dashboardPage .mycard.calendarCustom {
  /* padding: 0!important; */
  overflow: hidden;
  padding-bottom: 0 !important;
}

.calendarCustom .cardBody {
  padding: 0 !important;
}

.calendarCustom .react-calendar {
  border: 0;
  /* width: 100%; */
}

.calendarCustom .react-calendar__navigation {
  display: flex;
  height: 40px;
  margin-bottom: 27px;
  margin-top: 12px;
  margin-left: 0;
  margin-right: 0;
}

.calendarCustom
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday
  abbr {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #6f8381;
  border-bottom: none !important;
  cursor: default !important;
  text-decoration: unset;
}

.calendarCustom
  button.react-calendar__navigation__label
  span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #1d2524;
}

.calendarCustom
  button.react-calendar__tile.react-calendar__month-view__days__day {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #1d2524;
  max-width: 41px;
  height: 41px;
  border-radius: 100px;
  margin: 2px;
}

.calendarCustom
  button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: white !important;
  background: #007982 !important;
}

.calendarCustom .react-calendar__month-view__weekdays__weekday {
  max-width: unset;
}

.calendarCustom .react-calendar__month-view__weekdays {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.calendarCustom .react-calendar__month-view__days {
  display: flex;
  justify-content: unset;
}

.calendarCustom button.react-calendar__navigation__arrow {
  font-size: 20px;
}

.calendarCustom
  button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.calendarCustom
  button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none !important;
}

.calendarCustom .react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}

.calendarCustom .react-calendar__navigation button:enabled:hover {
  background-color: #e5fdff;
  border-radius: 12px;
}

.calendarCustom .react-calendar__navigation button:enabled:active {
  background-color: #00acb9;
  color: #fff;
}

.calendarCustom
  .react-calendar__navigation
  button:enabled:active
  span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #fff;
}

.CreatePlanModal .modal-content {
  background: #ffffff;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06),
    0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
}

.CreatePlanModal .modal-dialog {
  /* max-width: 792px; */
  max-width: 710px;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CreatePlanModal .modal-dialog .modal-body {
  /* padding: 53px 32px 32px 32px; */
  padding: 32px 32px 32px 32px;
}

.CreatePlanModal .modal-dialog .modal-body form textarea {
  /* min-height: 206px; */
  min-height: 120px;
}

/* custom radio button css */

.CreatePlanModal .checkboxBox .custom_radio {
  margin: 0;
}
.CreatePlanModal .checkboxBox .custom_radio input[type="radio"] {
  display: none;
}
.CreatePlanModal .checkboxBox .custom_radio input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  margin-right: 2em;
  cursor: pointer;
  line-height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}
.CreatePlanModal .checkboxBox .custom_radio input[type="radio"] + label:before,
.CreatePlanModal .checkboxBox .custom_radio input[type="radio"] + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  color: white;
  font-family: Times;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.CreatePlanModal .checkboxBox .custom_radio input[type="radio"] + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.1em #e7e7e7, inset 0 0 0 0px #c2c2c2;
}
.CreatePlanModal
  .checkboxBox
  .custom_radio
  input[type="radio"]
  + label:hover:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}
.CreatePlanModal
  .checkboxBox
  .custom_radio
  input[type="radio"]:checked
  + label:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 0.2em #00acb9, inset 0 0 0 6px #00acb9;
}

.CreatePlanModal .checkboxBox h5,
.CreatePlanModal label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1d2524;
  margin-bottom: 10px;
}

.CreatePlanModal .titleBox {
  margin-bottom: 35px;
}

.CreatePlanModal .titleBox img {
  margin-bottom: 12px;
}

.CreatePlanModal .titleBox h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1d2524;
}

.CreatePlanModal .react-datepicker__day--selected {
  background-color: #00acb9;
}

.CreatePlanModal .css-1okebmr-indicatorSeparator {
  display: none;
}

.dashboardPage .mycard .nodata_box h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1d2524;
  margin: 18px 0 0 0;
}

.dashboardPage .mycard .nodata_box p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.17px;
  color: #667085;
  margin: 6px 0 0 0;
}

.addProductbtn.invitestafflist {
  width: 155px;
  padding: 0 !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productFormBox.reportBatches {
  padding: 32px !important;
}


.counterfeitsReportsCard .mt-2.mt-md-0.mr-1,
.counterfeitsReportsCard .mt-2.mt-md-0.mx-1,
.counterfeitsReportsCard .mt-2.mt-md-0.ml-1 {
  flex: 1;
}
.counterfeitsReportsCard .graphDataCard {
  padding-bottom: 2.5em;
  position: relative;
}
.counterfeitsReportsCard .graphDataCard p {
  font-size: 14px;
  white-space: nowrap;
}
.counterfeitsReportsCard .graphDataCard h5 {
  font-size: 24px;
}
.counterfeitsReportsCard .graphDataCard .arrowBox span {
  font-size: 14px;
}
.counterfeitsReportsCard .graphDataCard .arrowBox {
  position: absolute;
  bottom: 15px;
}

.tableCard .counterfeifReport .statusReports {
  background: #eff5f5;
  border: 1px solid #e9f1f0;
  border-radius: 10px;
  width: 121px;
  height: 38px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableCard .counterfeifReport tr.btachRow:hover .statusReports {
  background: #ffffff;
  border: 1px solid #99a4a3;
}

.productFormBox.reportBatches .pagination a.active {
  background: #edfdfb;
  border: 1px solid #00acb9;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00acb9;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productFormBox.reportBatches .pagination a {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #344054;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.productFormBox.reportBatches .pagination a:not(.active):hover {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
}

.productFormBox.reportBatches.settings p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin-top: 8px;
  margin-bottom: 8px;
}

.productFormBox.reportBatches.settings h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #c60f0f;
}

.productFormBox.reportBatches.settings.Notify h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1e2524;
}

.productFormBox.reportBatches.settings.Notify p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin-top: 0;
  margin-bottom: 32px;
}

.productFormBox.reportBatches.settings.Notify .switchBox h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1e2524;
  margin: 0;
}

/* switch box css */
.switchBox .d-flex.align-items-center.justify-content-between {
  margin-bottom: 30px;
}

.switchBox .d-flex.align-items-center.justify-content-between:last-child {
  margin-bottom: 0;
}

.switchBox .switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
  margin: 0;
}

.switchBox .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchBox .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchBox .slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
}

.switchBox input:checked + .slider {
  background-color: #00acb9;
}

.switchBox input:focus + .slider {
  box-shadow: 0 0 1px #00acb9;
}

.switchBox input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.switchBox .slider.round {
  border-radius: 34px;
}

.switchBox .slider.round:before {
  border-radius: 50%;
}

.productFormBox.help.tutorials {
  padding: 40px 32px !important;
}

.productFormBox.help.tutorials .titleRow h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 39px;
  color: #1e2524;
  margin-bottom: 13px;
  text-align: center;
}
.productFormBox.help.tutorials .titleRow p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #667085;
  margin-bottom: 25px;
}

.productFormBox.help.tutorials .videoCard {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  padding: 20px 20px;
  margin-bottom: 30px;
}
.productFormBox.help.tutorials .videoCard h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(102, 112, 133, 0.7);
  margin-bottom: 14px;
}
.productFormBox.help.tutorials .videoCard h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  /* margin-bottom: 30px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 13px;
  min-height: 34px;
}

.productFormBox.help.tutorials .videoCard .cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productFormBox.help.tutorials .videoCard .cardFooter h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(102, 112, 133, 0.7);
  margin-bottom: 7px;
}
.productFormBox.help.tutorials .videoCard .cardFooter span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00acb9;
}

.productFormBox.help.tutorials .videoCard .cardFooter a.click {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00acb9;
  text-decoration: none;
}

.productFormBox.help.tutorials .videoCard .cardFooter img {
  margin-right: 12px;
}

.dashboardPage .nodataRecentScanHome {
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1d2524;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00acb9 !important;
  color: #fff!important;
}

.daterangepicker .drp-buttons button.applyBtn.btn.btn-sm.btn-primary {
  background-color: #00acb9;
  border-color: #00acb9;
}

.nodataTablePosition {
  position: absolute !important;
  width: 100% !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.CreatePlanModal .closeModalCreatePlan {
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
  display: inline-block;
  z-index: 9;
  transition: 0.2s;
}

.CreatePlanModal .closeModalCreatePlan:hover {
  transform: rotate(90deg);
}

.calendarCustom .react-calendar {
  width: 315px !important;
  max-width: 315px !important;
  min-width: 315px !important;
}

.col-md-6 .form-control.p-0.css-b62m3t-container .css-x19aeh-control {
  width: 100%;
  border: 0;
  border-radius: 20px;
  background-color: #fdfdfd;
  background: #fdfdfd;
}

.col-md-6 .form-control.p-0.css-b62m3t-container .css-1rsjho4-control {
  border: 0;
  box-shadow: none;
  outline: 0 !important;
  width: 100%;
  border-radius: 20px;
  background-color: #fdfdfd;
  background: #fdfdfd;
}

.emailAddresssModal.phoneNumber .companyDeatilContactNum .css-11d2qd3-container {
  background: #fdfdfd;
  border-radius: 6px;
  max-width: 75px;
  min-width: 75px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 15px 0px 0px 15px!important;
}

.invite_section .formCard .formStepsBox .form-group .companyDeatilContactNum {
  padding-left: 2px;
}

.invite_section
  .formCard
  .formStepsBox
  .form-group
  .companyDeatilContactNum
  .form-control {
  padding-left: 80px !important;
}

.invite_section
  .formCard
  .formStepsBox
  .form-group
  .companyDeatilContactNum
  .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.invite_section
  .formCard
  .formStepsBox
  .d-flex.form-control.group-input.companyDeatilContactNum::before {
  display: none;
}

.invite_section .formCard .formStepsBox .css-s79loo-ValueContainer {
  justify-content: center;
  font-weight: 700;
}

.CreatePlanModal form .form-control {
  font-size: 13px;
}

.permission-card form .form-control {
  font-size: 14px;
  z-index: 99;
}

.card.permission-card form .form-control.h-100.css-b62m3t-container {
  padding: 0;
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.card.permission-card
  form
  .form-control.h-100.css-b62m3t-container
  .css-7pckcd-control,
.card.permission-card
  form
  .form-control.h-100.css-b62m3t-container
  .css-djcy9t-control {
  box-shadow: none;
  width: 100%;
  border: 0 !important;
  background: transparent;
}

.img_placeholder {
  width: 60px;
  height: 60px;
  background: #00acb9;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

/* .staffListingui .row-grid-fixed{
  height: calc(100vh - 332px);
  position: relative;
} */

.staffListingui {
  min-height: calc(100vh - 342px);
  position: relative;
}

.staffListingui .row-grid-fixed .col-md-12.col-vd-nodata {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.staffListingui .row-grid-fixed .col-md-12.col-vd-nodata p {
  margin: 0;
}

.customDropdownBox.changeAssignee,
.customDropdownBox.updateTicket {
  transition: 0.3s !important;
  z-index: 9;
}

.customDropdownBox.changeAssignee:hover,
.customDropdownBox.updateTicket:hover {
  transform: translateY(-8px);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fdfdfd !important;
}

.col-vd-nodata.image-no-data-sm p a {
  color: #00acb9;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.card.permission-card
  form
  .form-control.h-100.css-b62m3t-container
  .css-1e78bi1-control,
.card.permission-card
  form
  .form-control.h-100.css-b62m3t-container
  .css-vm72g8-control {
  width: 100%;
  background: transparent;
  border: 0;
  box-shadow: none;
  border-radius: 10px !important;
}

.productFormBox.viewStaff_page .css-b62m3t-container .css-1rsjho4-control {
  border-radius: 10px !important;
}

.productFormBox.viewStaff_page .css-b62m3t-container {
  padding: 0;
  border-radius: 10px !important;
}

.productFormBox.viewStaff_page .css-b62m3t-container .css-x19aeh-control {
  width: 100%;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.createplanpage .mycard p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #667085;
  text-align: center;
  margin-bottom: 23px;
}
.createplanpage .mycard h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1e2524;
  margin: 0;
  text-align: center;
}

.createplanpage .mycard.subdomaincard button {
  position: absolute;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  top: 27px;
  right: 32px;
}

.createplanpage .mycard.graphcard .cardHeader h4 {
  font-size: 20px;
}

.batchTable.billingTable .createPlanRow .viewBtn {
  background: #00acb9;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  width: 100px;
  height: 40px;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.batchTable.billingTable .createPlanRow .viewBtn img {
  margin-right: 8px;
}

.batchTable.billingTable .createPlanRow td:nth-child(2) span.textContol {
  max-width: 450px;
  width: 450px;
}

.col-md-3 .form-control.h-100.css-b62m3t-container .css-1rsjho4-control {
  border: 0 !important;
  box-shadow: none !important;
}

.permission-card .css-g1d714-ValueContainer {
  height: 40px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  max-width: 75%;
  overflow-x: auto;
}

.permission-card .css-13jmfyo-multiValue {
  min-width: 70px;
}

/* width */
.permission-card .css-g1d714-ValueContainer::-webkit-scrollbar {
  height: 0px;
}

/* Track */
.permission-card .css-g1d714-ValueContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.permission-card .css-g1d714-ValueContainer::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.permission-card .css-g1d714-ValueContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.createplanpage .staffCardBody h4 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  letter-spacing: -0.5px !important;
  color: #1e2524 !important;
  margin-top: 64px !important;
  margin-bottom: 0 !important;
}

.createplanpage .staffCardBody .staffImages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.createplanpage .staffCardBody .staffImages .imgBox {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
}

.createplanpage .staffCardBody .staffImages .imgBox:first-child {
  margin-left: 0;
}

.createplanpage .staffCardBody .staffImages .imgBox:last-child {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
  background: #f5f9f9;
  border: 1px solid #e9f2f2;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  color: #6f8381;
}

.createplanpage .staffCardBody .inviteStaffBtn {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1d2524;
  border: 0;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 39px;
}

.createplanpage .staffCardBody .inviteStaffBtn img {
  margin-right: 7px;
}

.createplanpage .staffCardBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.purchasePage .productFormBox h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.5px;
  color: #1e2524;
  margin-bottom: 15px;
  text-align: center;
}

.purchasePage .productFormBox p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6f8381;
  text-align: center;
  margin-bottom: 28px;
}

.purchasePage .productFormBox .switchBox {
  display: flex;
  align-items: center;
}
.purchasePage .productFormBox .switchBox p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 0 12px;
}

.purchasePage .productFormBox .planGrid {
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06),
    0px 0px 7px -2px rgba(16, 24, 40, 0.08);
  border-radius: 12px;
  padding: 32px 28px;
  height: 461px;
  position: relative;
  margin: 25px 0;
}

.purchasePage
  .productFormBox
  .planGrid
  .btn_wrap.loaderBtn
  span.react-skeleton-load {
  width: 100% !important;
}

.purchasePage .productFormBox .planGrid.active {
  border: 1px solid #00acb9;
}

.purchasePage .productFormBox .planGrid h3,
.purchasePage .productFormBox .planGrid h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1e2524;
  text-align: left;
}

.purchasePage .productFormBox .planGrid h3 {
  margin-bottom: 12px;
}

.purchasePage .productFormBox .planGrid h4 {
  margin-bottom: 29px;
}
.purchasePage .productFormBox .planGrid p {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1e2524;
  margin-bottom: 12px;
}

.purchasePage .productFormBox .planGrid ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}
.purchasePage .productFormBox .planGrid ul li {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #6f8381;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.purchasePage .productFormBox .planGrid ul li:last-child {
  margin-bottom: 0;
}

.purchasePage .productFormBox .planGrid ul li img {
  margin-right: 7px;
}

.purchasePage .productFormBox .planGrid ul li span {
  word-break: break-all;
}

.purchasePage .productFormBox .planGrid .btn_wrap {
  padding: 0px 11px;
  margin-top: 40px;
  position: absolute;
  width: 80%;
  left: 50%;
  bottom: 7px;
  transform: translate(-50%, -50%);
}

.purchasePage .productFormBox .planGrid span.popularBatch {
  background: #00acb9;
  border-radius: 8px;
  width: 70px;
  height: 28px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 32px;
}

.purchasePage .productFormBox .planGrid.nodataGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.purchasePage .productFormBox .planGrid.nodataGrid img {
  width: 120px;
}
.purchasePage .productFormBox .planGrid.nodataGrid p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e2524;
  margin-bottom: 0;
  text-align: center;
  margin-top: 1em;
}

.purchasePage .slick-dots li {
  width: 40px;
}

.purchasePage .slick-dots li button {
  width: 40px;
}

.purchasePage .slick-dots li button:before {
  content: " " !important;
  background: #00acb9;
  width: 42px;
  height: 5px;
  border-radius: 10px;
}

table.table.batchTable.billingTable.purchase_Subscriptiontable tr td {
  padding: 29.5px 16px !important;
}

.purchasePage .hideComparison {
  position: relative;
  background: #00acb9;
  border: 1px solid #00acb9;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 12px;
  padding: 13px 17px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
}

.purchasePage .hideComparison img {
  position: absolute;
  right: 25px;
  transition: 0.2s;
}

.purchasePage .hideComparison[aria-expanded="true"] img {
  rotate: 180deg;
}

.productFormBox.methodRow.reportBatches.settings.mt-4.billings
  .innerHolder.paymentRow
  .react-skeleton-load {
  width: 100% !important;
}

/* .slick-dots li button:hover:before, .slick-dots li button:focus:before{
  opacity: .25!important;
} */

/* .permission-card .css-tlfecz-indicatorContainer{
  padding-left: 0;
  padding-right: 0;
} */

.settingModal .modal-dialog {
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingModal .modal-dialog .modal-content .modal-body {
  padding-top: 0 !important;
}

.settingModal .modal-dialog .modal-content .modal-body .companyName h5 {
  margin-bottom: 55px;
}

.settingModal
  .modal-dialog
  .modal-content
  .modal-body
  .moduleHolder
  .moduleName {
  padding: 12px 14px !important;
}

.banksCardRow .bankCard {
  width: 400px;
  height: 242px;
  background-size: cover;
  border-radius: 14px;
  position: relative;
}

.banksCardRow .americanExpress {
  background-image: url("../img/americanExpress.svg");
}

.banksCardRow .dinersClub {
  background-image: url("../img/dinersClub.svg");
}

.banksCardRow .discoverCard {
  background-image: url("../img/discoverCard.svg");
}

.banksCardRow .unionPay {
  background-image: url("../img/unionPay.svg");
}

.banksCardRow .jcbCard {
  background-image: url("../img/jcbCard.svg");
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.americanExpress
  .code {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 7px;
  top: 41.7%;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.americanExpress
  .validupto {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 18px;
  bottom: 25px;
  min-width: 60px;
  text-align: center;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.americanExpress
  .cardName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 42%;
  bottom: 15px;
  min-width: 300px;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.americanExpress
  .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 118px;
  bottom: 39px;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.dinersClub
  .validupto {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  top: 22.7%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.dinersClub
  .code {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 21px;
  bottom: 2%;
  width: 50px;
  text-align: left;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.dinersClub
  .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 118px;
  bottom: 27px;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.dinersClub
  .cardName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 31%;
  bottom: 3px;
  min-width: 210px;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.discoverCard
  .code {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 59px;
  bottom: 22%;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.discoverCard
  .validupto {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 50px;
  left: 47.7%;
  min-width: 60px;
  text-align: center;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.discoverCard
  .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 34.2%;
  bottom: 97px;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.discoverCard
  .cardName {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 47%;
  bottom: 3px;
  min-width: 300px;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.unionPay .code {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 10.6%;
  top: 52.6%;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.unionPay
  .validupto {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 42%;
  bottom: 60px;
  min-width: 60px;
  text-align: left;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.unionPay
  .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 117px;
  bottom: 44.3%;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.unionPay
  .cardName {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 42.5%;
  bottom: 3px;
  min-width: 300px;
}

.productFormBox.reportBatches.settings .banksCardRow .bankCard.jcbCard .code {
  display: none;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.jcbCard
  .validupto {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 1.5%;
  top: 55px;
  min-width: 60px;
  text-align: left;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.jcbCard
  .cardNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 116px;
  bottom: 11.3%;
}

.productFormBox.reportBatches.settings
  .banksCardRow
  .bankCard.jcbCard
  .cardName {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 42.5%;
  bottom: 3px;
  min-width: 300px;
}

.failedModal .modal-content {
  border: 0;
}

.failedModal .modal-dialog {
  max-width: 490px;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.failedModal .modal-dialog .modal-body {
  padding: 32px;
}

.failedModal .modal-dialog .modal-body .failedMessage .addProductbtn {
  font-weight: 500 !important;
}

.failedModal .modal-dialog .modal-body .failedMessage h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1e2524;
  margin-top: 30px;
  text-align: center;
}

.failedModal .modal-dialog .modal-body .failedMessage p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(102, 112, 133, 0.9);
  opacity: 0.75;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 30px;
}

.failedModal .modal-dialog .modal-body .successMessage img.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

/* success modal */

.successModal .modal-content {
  border: 0;
}

.successModal .modal-dialog {
  max-width: 400px;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.successModal .modal-dialog .modal-body {
  padding: 32px;
}

.successModal .modal-dialog .modal-body .failedMessage .addProductbtn {
  font-weight: 500 !important;
}

.successModal .modal-dialog .modal-body .failedMessage h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1e2524;
  margin-top: 30px;
  text-align: center;
}

.successModal .modal-dialog .modal-body .failedMessage p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(102, 112, 133, 0.9);
  opacity: 0.75;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 30px;
}

.successModal .modal-dialog .modal-body .successMessage img.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.paymentCardNoData {
  position: unset !important;
  transform: unset !important;
}

.paymentCardNoData p {
  font-weight: 600 !important;
  color: #020202 !important;
  font-size: 14px !important;
}

.paymentCardNoData img {
  width: 120px;
}

.productFormBox.reportBatches.settings p.listHeading.titleCard {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6f8381;
  position: unset;
  margin-top: 30px;
}

.paymentSection .paymentRow .sliderHolder .slick-dots {
  bottom: -46px !important;
}

.paymentSection .paymentRow .sliderHolder {
  margin-bottom: 50px !important;
}

.paymentSection .paymentRow .sliderHolder {
  margin-top: 0 !important;
}

.innerHolder.paymentRow .cardHolder {
  padding-top: 40px !important;
}

.paymentSection .paymentRow .sliderHolder {
  margin-top: 26px !important;
}

.scrollBoxBtn {
  /* background: red;
  height: 48px;
  position: absolute;
  width: 238px;
  z-index: 9; */
}

.methodRow .accrordianHolder .rightAccordion {
  margin-top: 14px !important;
  padding-left: 3px !important;
}

.accrordianHolder .rightAccordion .accordion-item .accordion-title .title {
  padding-left: 5px;
  padding-top: 3px;
}

.accrordianHolder
  .rightAccordion
  .accordion-item
  .accordion-title
  .title.active {
  opacity: 1 !important;
}

.nodataTablePosition.paymentCardNoData.upgradePlan {
  position: absolute !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 54% !important;
}

.logoutModal.Custom2 .modal-content .modal-footer button.logout-btn {
  text-transform: capitalize !important;
}

.TotalScannedProduct
  .apexcharts-canvas
  .apexcharts-series
  path {
  /* clip-path: inset(46% 0% 35% 0% round 25px 25px 25px 25px) !important;
  -webkit-clip-path: inset(46% 0% 35% 0% round 25px 25px 25px 25px) !important;
  clip-path: inset(46% 0% 35% 0% round 25px 25px 25px 25px) !important; */
}

.logoutModal.Custom3 .modal-content .modal-header button{
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
}

.logoutModal.Custom3 .modal-content input.form-control {
  height: 40px;
  font-size: 14px;
}

.logoutModal.Custom3 .modal-content textarea.form-control{
  font-size: 14px;
}

.TrackRequest .formCard{
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}
.TrackRequest .formStepsBox{
  margin: 30px 0 0 0!important;
}
.TrackRequest .formStepsBox label{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.17px;
  color: #8A94A6;
}

.invite_section .expiredCard .cardBody.TrackRequest{
  padding: 0 80px;
  height: auto;
}

.invite_section .expiredCard .cardBody.TrackRequest .cancel_btn{
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #8894A8;
  background: transparent;
  height: 45px;
  width: 100%;
  margin-top: 20px;
}
.invite_section .expiredCard .cardBody.TrackRequest .track_btn{
  border: 1px solid #0F51A1;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  background: #0F51A1;
  height: 45px;
  width: 100%;
  margin-left: 35px;
  margin-top: 20px;
}

.profileForm .downloadBtnBox button{
  border: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #00ACB9;
  /* width: 101px; */
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #00ACB9;
  border-radius: 4px;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0 8.8px;
}

.profileForm .downloadBtnBox button img{
  margin-right: 9px;
}

.profileForm .downloadBtnBox button img.IconWhite{
  position: absolute;
  left: 9px;
  opacity: 0;
}

.profileForm .downloadBtnBox button:hover{
  background: #00ACB9;
  color: #fff;
}

.profileForm .downloadBtnBox button:hover img.IconWhite{
  opacity: 1;
}

.profileForm .downloadBtnBox button:hover img.IconGreen{
  opacity: 0;
}

.profileForm .chooseFileBox {
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 14px;
}
.profileForm .chooseFileBox input[type="file"]{
  display: block;
  position: absolute;
  opacity: 0;
  height: 24px;
  cursor: pointer;
  left: -120px;
}

.profileForm .chooseFileBox.downloadBtnBox button{
  left: 0!important;
  right: auto;
  top: 0;
}

.profileForm .chooseFileBox.downloadBtnBox .btn_collapes{
  overflow: hidden;
  width: 63px;
  height: 24px;
  position: relative;
}

.profileForm .chooseFileBox.downloadBtnBox p{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin: 0 0 0 12px;
}

.profileForm .imgViewBox{
  border-radius: 200px;
  overflow: hidden;
  height: 264px;
}
.profileForm .imgViewBox img.userProfile{
  width: 100%;
}

.profileForm .imgViewBox .editUserPic{
  position: absolute;
  right: 45px;
  top: 170px;
  overflow: hidden;
}

.profileForm .imgViewMainBox h6.userName{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  text-align: center;
  margin-bottom: 0;
  margin-top: 12px;
}

.productFormBox.profileMainBox{
  padding: 32px 33px !important;
  margin-bottom: 45px;
}

.productFormBox.profileMainBox label{
  margin-bottom: 7px!important;
  padding-left: 1px!important;
}

.profileForm .imgViewBox .editUserPic input[type="file"]{
  display: block;
  position: absolute;
  top: 0;
  left: -110px;
  opacity: 0;
}

.editbtn_table{
  background: #00ACB9;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 12px;
  outline: 0;
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  width: 90px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileMainBox h4.heading{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 30px;
}

.profileMainBox .addEmailBtn{
  background: #00ACB9;
  border: 1px solid #00ACB9;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  width: 192px;
  height: 48px;
  text-align: center;
}

.profileMainBox .addEmailBtn:hover{
  color: #fff!important;
}

.profileMainBox .addEmailBtn img{margin-right: 8px;}

.profileMainBox .profileForm hr{
  border-top: 1px solid #F1F4F3;
  margin-top: 30px;
  margin-bottom: 35px;
}

.profileMainBox .trashBtn{
  border: 0;
  background: transparent;
  width: 50px;
  height: 48px;
  padding: 0;
  position: absolute;
  right: -47px;
  top: 37px;
}

.profileMainBox .trash_option{
  position: relative;
}

.profileMainBox .trash_option .trashBtn{
  position: absolute;
  top: 0;
}

.profileMainBox .trash_option .trashBtn{
  display: none;
}

.profileMainBox .trash_option .position-relative:hover .trashBtn{
  display: block;
}

/* .profileForm .chooseFileBox input[type="file"]:hover ~ button{
  background: #00ACB9;
  color: #fff;
} */

.emailAddresssModal .modal-dialog{
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 608px;
}

.emailAddresssModal .modal-dialog .modal-content{
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%), 0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
}

.emailAddresssModal .modal-dialog .modal-content .modal-header{
  padding: 0;
  border-color: #F1F4F3;
}

.emailAddresssModal .modal-dialog .modal-content .modal-header button{
  padding: 0;
  border-radius: 100%;
}

.emailAddresssModal .modal-dialog .modal-content .modal-header .modal-title{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 18px;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body{
  padding: 0;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body p{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
  margin-top: 30px;
  margin-bottom: 12px;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .form-control{
  height: 40px;
  font-size: 14px;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .otp_btn{
  margin-top: 20px;
  background: #00ACB9;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 3%);
  border-radius: 10px;
  height: 40px;
  width: 200px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.emailAddresssModal .addressForm .form-group label{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #667085;
  margin-bottom: 8px;
  padding-left: 4px;
}

.emailAddresssModal .addressForm.customchanges .form-group label{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
}

.emailAddresssModal.addressModalbox .modal-dialog{
  min-width: 709px;
}

.emailAddresssModal.addressModalbox .modal-dialog .form-group{
  margin-bottom: 30px;
}

.emailAddresssModal.addressModalbox .css-1s2u09g-control, .emailAddresssModal.addressModalbox .css-1pahdxg-control {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding-left: 14px;
  outline: 0;
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #e7e7e7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 2%);
  border-radius: 10px;
  color: #667085 !important;
  height: 40px;
}

form .otp_form .form-control{
  width: 40px;
  margin-right: 30px;
}

form .otp_form .form-group{
  margin-bottom: 8px;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .otp_count{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin-top: 0;
  margin-bottom: 20px;
}

.emailAddresssModal.phoneNumber h5{
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 32px;
}

.emailAddresssModal.phoneNumber p{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
  margin-top: 8px!important;
  margin-bottom: 12px;
}

.emailAddresssModal.phoneNumber .areaCode{
  background: #fdfdfd;
  border-radius: 10px 0px 0px 10px;
  border-right: 1px solid #F1F4F3;
}

.emailAddresssModal.phoneNumber .areaCode .css-7ouwo3-singleValue{
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.emailAddresssModal.phoneNumber .areaCode .css-clq5q2-control{
  width: 100%;
}

.emailAddresssModal.phoneNumber .areaCode .css-clq5q2-control{
  width: 100%;
}

.emailAddresssModal.phoneNumber .d-flex.form-control.group-input.companyDeatilContactNum{
  padding-right: 0;
}

.emailAddresssModal.phoneNumber .form-group{
  width: 100%;
}

.emailAddresssModal.phoneNumber .form-group input[type="tel"]{
  width: 100%;
}

.productFormBox.passSettings h4{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #F1F4F3;
}

.productFormBox.passSettings{
  padding: 32px 33px !important;
}

.productFormBox.passSettings p{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin-top: 30px;
  margin-bottom: 40px;
}

.productFormBox.passSettings .infoBtn{
  padding: 0;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productFormBox.passSettings .customGrid{
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  margin-top: 30px;
}
.productFormBox.passSettings .customGrid h3{
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin: 0;
  padding: 0;
}
.productFormBox.passSettings .customGrid h4{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #667085;
  border: 0;
  margin: 0;
  padding: 0;
}
.productFormBox.passSettings .customGrid button{
  background: transparent;
  border: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.listing{
  list-style: none;
  padding: 0;
  margin-bottom: 70px;
}
.listing li{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #1E2524;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.listing li:last-child{
  margin-bottom: 0;
}
.listing li button{
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}
.listing li button img{
  width: 20px;
}

.box_notify{
  background: #EFF5F5;
  border: 1px solid #E9F1F0;
  border-radius: 12px;
  color: rgb(102 112 133 / 60%);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px;
  width: 764px;
  height: 68px;
  /* margin-bottom: 113px; */
}

.box_notify a{
  font-weight: bold;
  color: rgb(30 37 36 / 60%);
  text-decoration: underline;
  font-style: italic;
}

.box_notify a:hover{
  color: rgb(30 37 36 / 70%);
}

.googleAuthenticator .otp_row h4{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1E2524;
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 0;
}

.googleAuthenticator .otp_row .otp_form{margin-bottom: 112px;}

.googleAuthenticator .otp_row .otp_form.qrCodeBox{
  margin-bottom: 40px;
}

.googleAuthenticator .otp_row .otp_form .form-group{
  margin-bottom: 0;
}
.googleAuthenticator .otp_row .otp_form .form-control{
  width: 40px;
  height: 40px;
}

.googleAuthenticator .otp_row .otp_form .form-group:last-child .form-control{
  margin-right: 0;
}

.googleAuthenticator .action-Btns .btn{
  width: 200px;
}

.googleAuthenticator .action-Btns .btn:first-child{
  /* margin-right: 30px; */
}

.productFormBox form .form-control{
  display: flex;
  align-items: center;
}

.googleAuthenticator .qrCodeBox p{
  margin-top: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1E2524;
  margin-bottom: 19px;
}
.googleAuthenticator .qrCodeBox h4{
  margin-top: 25px;
  margin-bottom: 13px;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1E2524;
}
.googleAuthenticator .qrCodeBox h5{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1E2524;
  margin: 0;
  padding-top: 13px;
}

.googleAuthenticator .regenerateKeySection{
  height: 436px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.googleAuthenticator .regenerateKeySection h4{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1E2524;
  padding: 0;
  margin-bottom: 100px;
}
.googleAuthenticator .regenerateKeySection form .customCancelBtn{
  width: 283px;
}
.googleAuthenticator .regenerateKeySection form .customSubmitBtn{
  width: 283px;
}

.emailAddresssModal.regenerateKeyModal h5{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
  margin-bottom: 8px;
  margin-top: 32px;
}

.tooltipCustom .popover-header{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin: 20px 28px 0px;
  padding: 0 0 17.75px 0;
  background: #fff;
  border-bottom: 1px solid #F1F4F3;
}
.tooltipCustom .popover-body{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #6F8381;
  margin: 17.25px 28px 20px;
  padding: 0;
}

.tooltipCustom{
  background: #FFFFFF;
  box-shadow: 0px 8px 28px rgb(16 24 40 / 6%), 0px 0px 7px rgb(16 24 40 / 8%);
  border-radius: 12px!important;
  border: 0!important;
  max-width: 600px!important;
  min-width: 600px!important;
}

.popover-arrow{
  /* top: 15px!important; */
  width: 20px;
  height: 20px;
  right: -13px;
  background: transparent;
  color: #fff;
  font-size: 16px;
}

.popover-arrow::before{
  content: "►";
}

.phoneNumber .phoneInputBox {
  padding: 0 0 0 14px;
}

.phoneInputBox .PhoneInput{
  display: flex;
  align-items: center;
}

.phoneInputBox .PhoneInput .PhoneInputInput{
  height: 38px;
  border: 0;
  background: transparent;
  outline: 0;
} 

/* .phoneInputBox .PhoneInput .PhoneInputInternationalIconPhone{
  display: none!important;
} */

.productFormBox.passSettings.alertCard h5{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 12px;
}
.productFormBox.passSettings.alertCard h3{
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin: 0 110px 0 0;
}
.productFormBox.passSettings.alertCard p{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #667085;
  margin: 0 0 30px 0;
}

.productFormBox.passSettings.SocialMediaCard p{
  margin: 0!important;
  text-align: center;
}
.productFormBox.passSettings.SocialMediaCard h5{
  text-align: center;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

.productFormBox.passSettings.SocialMediaCard .mr-30px{
  margin-right: 30px;
}

.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails{
  margin-bottom: 0;
}
.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr{}
.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr td{
  border: 0;
  padding: 0 0 15px 0;
  vertical-align: middle;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr:last-child td{
  padding: 0!important;
}

.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr td .socialProfile h6{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 2px;

}
.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr td .socialProfile p{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  text-align: left;
  letter-spacing: 0;
}

.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr td:last-child{
  text-align: right;
}
.productFormBox.passSettings.SocialMediaCard .table.SocialloginDetails tr td button{
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 0;
  display: inline-block;
}

.productFormBox.passSettings.deleteAccountBox p{
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #C60F0F;
  margin-top: 30px;
  margin-bottom: 40px;
}
.productFormBox.passSettings.deleteAccountBox .customSubmitBtn{
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.productFormBox.passSettings.SocialMediaCard table.activeSession tr td .socialProfile h6{
  font-size: 20px!important;
  margin-bottom: 8px!important;
}
.productFormBox.passSettings.SocialMediaCard table.activeSession tr td .socialProfile p{
  font-size: 14px!important;
}
.productFormBox.passSettings.SocialMediaCard table.activeSession tr td span.text_black{
  color: #1E2524;
}
.productFormBox.passSettings.SocialMediaCard table.activeSession tr td span.text_red{
  color: #C60F0F;
}

.productFormBox.passSettings.SocialMediaCard table.activeSession tr td{
  padding: 0 0 30px 0!important;
}

.accordianBox{
  margin-top: 30px;
}

.accordianBox .accordion-button{
  width: 100%;
  border: 0;
  background: transparent;
  padding: 0;
}
.accordianBox .accordion-button h4{
  padding: 0;
  border: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: #1E2524;
}

.accordianBox .accordion-button h4.browser{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 250px;
  text-align: center;
}
.accordianBox .accordion-button h4.location{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 300px;
  text-align: center;
}

.accordianBox .accordion .accordion-collapse .accordion-body{
  background: rgba(231, 231, 231, 0.25);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 10px;
  padding: 2px 32px 32px;
  margin-bottom: 32px;
}

.accordianBox .accordion .accordion-collapse .accordion-body .row h5{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1E2524;
  margin-bottom: 8px;
  margin-top: 30px;
}
.accordianBox .accordion .accordion-collapse .accordion-body .row p{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.17px;
  color: #667085;
  margin: 0;
}

.support_contact .contactGrid{
  background: rgba(231, 231, 231, 0.1);
  border: 1px solid #E7E7E7;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 1%);
  border-radius: 10px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.support_contact .contactGrid h5{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1E2524;
  margin-top: 15px;
  margin-bottom: 0;
}
.support_contact .contactGrid h6{
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #99A4A3;
  margin-top: 4px;
  margin-bottom: 25px;
}
.support_contact .contactGrid p{
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #1E2524;
  margin-bottom: 0;
}

.productFormBox.termCondition{
  padding: 32px!important;
}
.productFormBox.termCondition h1{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  color: #1E2524;
  text-align: center;
  margin-bottom: 12px;
}
.productFormBox.termCondition h2{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #667085;
  margin-bottom: 40px;
}
.productFormBox.termCondition h3{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #1E2524;
  margin-bottom: 30px;
}
.productFormBox.termCondition h4{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.productFormBox.termCondition ul{
  margin-left: 1.4em;
  margin-bottom: 30px;
}
.productFormBox.termCondition ul li{
  font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #667085;
    margin-bottom: 12px;
}

.productFormBox.termCondition ul li:last-child{
  margin-bottom: 0;
}

.productFormBox.termCondition ul li a{
  color: #00ACB9;
  text-decoration: underline;
}

.notification_aside{}
.notification_aside .dropdown-menu{
  background: #FFFFFF;
  box-shadow: 0px 8px 28px 2px rgb(16 24 40 / 10%), 0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px;
  width: 501px;
  padding: 32px 12px 32px 32px;
}

.notification_aside .dropdown-menu h4{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.5px;
  color: #1E2524;
  margin-bottom: 30px;
}

.notification_aside .dropdown-menu .scroll_box{
  height: 473px;
  overflow: auto;
}
.notification_aside .dropdown-menu .scroll_box .notify_row{
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.notification_aside .dropdown-menu .scroll_box .notify_row:hover{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.255) 0%, rgba(229, 253, 255, 0.289) 16.15%, rgba(229, 253, 255, 0.6885) 51.52%, rgba(229, 253, 255, 0.2975) 85.3%, rgba(255, 255, 255, 0.255) 100%);
}

.notification_aside .dropdown-menu .scroll_box .notify_row.unread::before{
  content: "";
  width: 8px;
  height: 8px;
  background: #00ACB9;
  position: absolute;
  border-radius: 50px;
  left: 0;
}

.notification_aside .dropdown-menu .scroll_box .notify_row:hover .closeNotify{
  display: block;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .closeNotify .focus, .notification_aside .dropdown-menu .scroll_box .notify_row .closeNotify:focus{
  box-shadow: none!important;
  outline: 0!important;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .img{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafa;
  border-radius: 100px;
  margin-right: 11px;
  overflow: hidden;
}

.notification_aside .dropdown-menu .scroll_box .notify_row{
  padding-bottom: 10px;
  border-bottom: 1px solid #F1F4F3;
  padding-top: 10px;
  margin-right: 20px;
}

.notification_aside .dropdown-menu .scroll_box .notify_row:last-child{
  border-bottom: 0!important;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .img img{
  width: 60px;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .closeNotify{
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 14px;
  display: none;
  transition: .2s;
}

.notification_aside .dropdown-menu .scroll_box .notify_row .closeNotify:hover{
  transform: scale(1.09);
}

.notification_aside .dropdown-menu .scroll_box h4{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1E2524;
  margin-bottom: 8px;
}
.notification_aside .dropdown-menu .scroll_box h5{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #6F8381;
  margin: 0;
}

.notification_aside .dropdown-menu .buttonBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-right: 20px;
}
.notification_aside .dropdown-menu .buttonBox .cancel_btn{
  border: 1px solid #E9F1F0;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
  width: 204px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #344054;
}
.notification_aside .dropdown-menu .buttonBox .clear_btn{
  background: #00ACB9;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 3%);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  width: 204px;
  height: 48px;
}

.verifyidentity_box .arrowBtn{
  position: absolute;
  top: 1px;
  right: 10px;
  height: 40px;
}

.verifyidentity_box .arrowBtn:focus{
  box-shadow: none!important;
}

.verifyidentity_box .otp_form .form-group{
  margin-bottom: 0!important;
}

.verifyidentity_box .otp_form .form-group .form-control{
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
}

.verifyidentity_box.otp h5{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #667085;
  margin-bottom: 12px;
}
.verifyidentity_box.otp p{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #667085;
  margin-top: 8px;
  color: #00ACB9;
}

.verifyidentity_box.otp .customSubmitBtn.submitbtn{
  min-width: 250px!important;
  width: 250px!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 17px!important;
}



.emailAddresssModal .modal-dialog .modal-content .modal-body .otp_emailForm p{
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #6F8381;
  font-size: 12px;
  font-weight: 400;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body .otp_emailForm input[type="tel"]{
  width: 40px!important;
  height: 40px!important;
}

.OwnerInformationCard .form-control.css-b62m3t-container .css-1s2u09g-control, .OwnerInformationCard .form-control.css-b62m3t-container .css-1pahdxg-control{
  margin-top: -6px;
  height: 45px;
  padding-left: 0;
}

.OwnerInformationCard .form-control.css-b62m3t-container .css-319lph-ValueContainer{
  padding-left: 0;
}

.rightAccordion .iconHolder {
  border: 1px solid #EFF5F5;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .form-control.companyDeatilContactNum .form-group{
  width: 100%!important;
}

.emailAddresssModal .modal-dialog .modal-content .modal-body form .form-control.companyDeatilContactNum{
  padding-right: 0!important;
}

.companyDeatilContactNum .css-11d2qd3-container .css-clq5q2-control{
  width: 100%!important;
  text-align: center!important;
  background: #fdfdfd!important;
  border-right: 1px solid #e7e7e7!important;
  border-radius: 15px 0px 0px 15px!important;
}

table.table.batchTable.intableCheckbox.reportScanTable tr td .textContol{}
table.table.batchTable.intableCheckbox.reportScanTable tr td:nth-child(3) .textContol{
  font-size: 12px;
  color: #5d6d6a;
  -webkit-line-clamp: 2!important;
}

.form-control.css-b62m3t-container .css-1rsjho4-control, .form-control.css-b62m3t-container .css-x19aeh-control{
  background: transparent;
  border: 0;
  width: 100%;
  border-color: transparent;
  box-shadow:none;
}

.form-control.css-b62m3t-container .css-1rsjho4-control:hover, .form-control.css-b62m3t-container .css-x19aeh-control:hover{
  border-color: transparent;
  box-shadow:none;
}
/* 
.form-control.css-b62m3t-container .css-1rsjho4-control:focus, .form-control.css-b62m3t-container .css-x19aeh-control:focus{
 outline: 0;
} */

table.table.SocialloginDetails.activeSession .activeSession_textlimit{
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
  max-width: 450px;
}

.productFormBox.profileMainBox .logo_name_length{
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical!important;
  width: 190px!important;
  height: 40px!important;
  overflow-wrap: break-word;
}

@media(max-width: 1290px){
  .exportRow .button_grp button{
    min-width: 80px;
  }

  .exportRow .datePickerBox{
    margin-left: 15px;
  }
}

@media (min-width:1200px){
  section.formLeftSection .verifyidentity_box .arrowBtn{
    right: 10px;
  }
}

@media(max-width: 1185px){
  .exportRow .datePickerBox input{
    width: 175px;
  }
  .tableFilter input[type="search"]{
    width: 303px!important;
  }

  .tableFilter .addProductbtn.invitestafflist{
    width: 131px;
    text-align: center;
  }
}

@media(max-width: 767px){
  .reportBatches .exportRow .datePickerBox{
    margin-left: 0;
  }
  .reportBatches .exportRow .datePickerBox input {
    width: 100%;
  }
}

@media(max-width: 992px){
  .tableFilter .addProductbtn.invitestafflist{
    width: 200px;
  }

  .reportBatches .exportRow .button_grp{
    overflow: hidden;
  }

  .reportBatches .exportRow .datePickerBox{
    width: auto;
  }

  
}

@media (min-width: 992px) and (max-width: 1370px) {
  .calendarCustom .react-calendar {
    transform: scale(0.85) translateY(-20px);
  }

  /* .calendarCustom .react-calendar{
    width: 265px!important;
    max-width: 265px!important;
    min-width: 265px!important;
  } */
  /* .calendarCustom button.react-calendar__tile.react-calendar__month-view__days__day{
    max-width: 33px;
    height: 33px;
    margin: 2px 2px 10px 2px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  } */
}

@media (min-width: 992px) and (max-width: 1100px) {
  .calendarCustom .react-calendar {
    transform: scale(0.8) translateY(-40px);
  }
  .calendarCustom
    button.react-calendar__tile.react-calendar__month-view__days__day {
    margin-bottom: 11px;
  }
  /* .dashboardPage .mycard.calendarCustom .cardBody{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 44%;
  } */
}

@media (max-width: 1185px) {
  .tableFilter {
    /* flex-direction: column; */
    /* overflow-x: auto; */
  }
  .tableFilter h3 {
    margin-bottom: 1em;
  }
}

@media (max-width: 1362px) {
  form button.customCancelBtn.choosebtb.mt-0.mr-3.btn {
    font-size: 13px;
  }
}

@media (max-width: 993px) {
  .tableFilter {
    flex-direction: column;
  }
  .tableFilter .filter_holder .no-arrow.dp-right.customDropdownBox.dropdown{
    width: 100%;
  }
  .tableFilter .filter_holder .customDropdownBox button{
    width: 100%;
  }
  .dropdown-custom.dropdown-toggle{
    min-width: auto;
  }
  .tableFilter .addProductbtn{
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 991px) {
  section.formLeftSection .logo {
    width: 100%;
  }

  section.formLeftSection .glassEffects {
    padding: 75px 45px;
    margin: 0 30px;
  }
}

@media (max-width: 767px) {
  /* section.formLeftSection{
        height: auto;
    } */
  section.formLeftSection form {
    padding-right: 0;
  }
  section.formLeftSection {
    overflow-x: hidden;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  section.formLeftSection {
    padding-left: 1em;
    padding-right: 1em;
  }
  .counterfeitModeltwo .modal-dialog {
    min-width: auto;
  }

  .CreatePlanModal .modal-dialog {
    height: auto;
  }
}

@media (max-width: 440px) {
  section.formLeftSection .buttonSetPassword {
    flex-direction: column-reverse;
  }
  section.formLeftSection .buttonSetPassword .customCancelBtn {
    margin-right: 0;
    margin-top: 20px;
  }
}

@media (max-width: 400px) {
  section.formLeftSection h2 {
    font-size: 26px;
  }

  section.formLeftSection {
    height: auto !important;
  }

  section.formLeftSection .glassEffects {
    padding: 45px 35px;
    margin: 0 0px !important;
  }
  section.formLeftSection .logo {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  /* #wrapper #content-wrapper {
        margin-left: 16rem;
    } */
}

@media (max-width: 1080px) {
  .productFormBox.manageVideosGrid.manageVideoMainCard_height
    .col-md-12.col-vd-nodata
    img {
    width: 100px !important;
  }
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs img,
  td.text-center.image-no-data-sm.nodataTablePosition img {
    max-width: 100px;
  }
  .staffListingui .col-vd-nodata img {
    max-width: 100px !important;
  }
}

@media (min-width: 993px) and (max-width: 1080px) {
  .tableFilter input[type="search"]{
    width: 245px;
  }
}

@media (max-height: 768px) {
  .CreatePlanModal .modal-dialog {
    height: auto;
  }
}

@media (max-height: 700px) {
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs,
  td.text-center.image-no-data-sm.nodataTablePosition {
    padding-top: 70px;
  }
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs img,
  td.text-center.image-no-data-sm.nodataTablePosition img {
    max-width: 100px;
  }
  .tableCard table.table tbody tr.no-tr td.image-no-data-xs p,
  td.text-center.image-no-data-sm.nodataTablePosition p {
    margin-bottom: 0;
  }
  .productFormBox.manageVideosGrid.manageVideoMainCard_height
    .col-md-12.col-vd-nodata
    img {
    width: 100px !important;
  }
  .staffListingui .col-vd-nodata img {
    max-width: 100px !important;
  }
}

html,
body,
#root,
#root .App,
#root .App #wrapper {
  height: 100%;
}
