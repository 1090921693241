.dount_chart li {
    list-style: none;
    position: relative;
    padding-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #1D2524;
    padding-bottom: 37px;
}

.dount_chart {
    padding: 0;
    /* position: absolute;
    right: 40px; */
}

.dount_chart span {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    position: absolute;
    left: -5px;
    top: 2px;
}

.dount_chart .pending {
    background: #246A18;
}

.dount_chart .Completed {
    background: #F49E1C;
}

.dount_chart .Review {
    background: #C60F0F;
}

.dount_chart .Acknowledge {
    background: #344054;
}

.apexcharts-legend {
    justify-content: flex-start !important;
}


.apexcharts-legend .apexcharts-legend-series {
    margin: 2px 27px 0 0 !important;
}

.eventDate {
    color: rgba(198, 15, 15, 0.7) !important;
}

.nextEventDate {
    color: #F49E1D !important;
}

.futureEventDate {
    color: #667085 !important;
}

.fotter_label span {
    padding-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 24px;
    letter-spacing: 0.121429px;
    color: #8A94A6;
}

.contentDate {
    color: white !important;
    background: #00ACB9 !important;
    position: relative;
    overflow: unset !important;
}

.contentBefore {
    position: absolute;
    width: 18px;
    height: 18px;
    background: #EFF5F5;
    content: '';
    top: -4px;
    border-radius: 50px;
    right: -2px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #1D2524;
}

.dount_chart li p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1D2524;
    margin: 0;
}

.cardHeader .span,
.cardHeader .span:focus,
.cardHeader .span:focus-visible {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #667085 !important;
    background: transparent !important;
    border: 0 !important;
    box-shadow: unset !important;
}

.gmnoprint {
    display: none !important;
}

.chartHolder {
    position: absolute;
    left: 0;
}

.counterCard {
    position: relative;
}

.dount_chart span.chart_legends {
    white-space: nowrap;
    position: unset;
}

.notifyLink {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    color: #1D2524;
    border-bottom: 1px solid #F1F4F3;
    padding: 7px 0;
}

.notifyLink:hover {
    text-decoration: none;
    color: #1D2524;
}

.dashboardPage .mycard .cardBody.card_overflow{
    overflow: visible;
}
@media screen and (min-width:1440px) {
    /* .dashboardPage .mycard .cardBody {
        min-height: 403px !important;
    } */
}