.invite_section {
    background-image: url("../../../assets/img/register_form_bg.svg");
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 51px 305px;
}

.formCard {
    background: #ffffff;
    box-shadow: 0px 2px 51px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
}

.invite_section .formCard .cardHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-bottom: 1px solid #f1f2f4;
}

.invite_section .formCard .cardHeader img {
    width: 270px;
}

.invite_section .formCard .cardBody {
    padding: 20px;
}

.invite_section .formCard .cardBody h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 20px;
}

.invite_section .formCard .steps_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.invite_section .formCard .steps_box::after {
    content: "";
    height: 1px;
    background: #0E50A0;
    position: absolute;
    width: 81%;
    transform: translate(-50%, -50%);
    left: 100%;
    top: 25%;
    opacity: 0.2;
}

.invite_section .formCard .steps_box:last-child:after {
    display: none;
}

.invite_section .formCard .steps_box .button {
    background: rgba(15, 81, 161, 0.1);
    width: 40px;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #4A4A4A;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 6px;
    border: 1px solid transparent;
}

.invite_section .formCard .steps_box.active .button {
    border: 1px solid #0E50A0;
    color: #0E50A0;
}

/* .invite_section .formCard .steps_box:active +  .steps_box::after{
    opacity: 1;
} */

.invite_section .formCard .steps_box.active:nth-child(1):after {
    opacity: 1;
}

/* .invite_section .formCard .steps_box.active:nth-child(2):after{
    opacity: 0.2;
} */


.invite_section .formCard .react-datepicker__header {
    background-color: #0f51a1 !important;
}

.invite_section .formCard .react-datepicker .react-datepicker__day--selected {
    background-color: #0f51a1 !important;
}

.invite_section .formCard .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #0f51a1;
}

.invite_section .formCard .react-datepicker__day--keyboard-selected,
.invite_section .formCard .react-datepicker__month-text--keyboard-selected,
.invite_section .formCard .react-datepicker__quarter-text--keyboard-selected,
.invite_section .formCard .react-datepicker__year-text--keyboard-selected {
    background-color: #0f51a1 !important;
}

.invite_section .formCard .steps_box h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4A4A4A;
    margin: 10px 0 0 0;
}

.invite_section .formCard .steps_mainbox {
    margin: 0 70px;
}

.invite_section .formCard .formStepsBox {
    margin: 40px 50px 30px;
}

.invite_section .formCard .formStepsBox form label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.17px;
    color: #8A94A6;
    margin-bottom: 10px;
    padding-left: 0;
}



.invite_section .formCard .formStepsBox form .calneder_icon {
    position: absolute;
    right: 28px;
    top: 44px;
}

.invite_section .formCard .formStepsBox form input[type='file'] {
    display: block;
    opacity: 0;
    transform: translateX(-110px);
    cursor: pointer;
    height: 100%;
}

.invite_section .formCard .formStepsBox form .customChooseFile {
    height: 45px;
    border-radius: 4px;
    background: #fff;
    box-shadow: none;
    border: 1px solid #E8E8E8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0A1F44 !important;
    position: relative;
}

.invite_section .formCard .formStepsBox form .customChooseFile span {
    background: #EBEBEB;
    border-radius: 4px;
    display: block;
    overflow: hidden;
    width: 100px;
    height: 85%;
    position: absolute;
    right: 0;
    transform: translate(-3%, -50%);
    top: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #8894A8;
}

.invite_section .formCard .formStepsBox form .customChooseFile span::before {
    content: "Browse";
    transform: translate(-50%, -50%);
    left: 50%;
    position: absolute;
    top: 50%;
    line-height: 1;
}

.invite_section .formCard .formStepsBox form span.css-1okebmr-indicatorSeparator {
    display: none;
}

.invite_section .formCard .formStepsBox .submitButton {
    background: #0F51A1;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 45px;
    width: 316px;
    border: 0;
    outline: 0;
    position: relative;
}

.invite_section .formCard .formStepsBox .submitButton:disabled {
    background: rgba(102, 112, 133, 0.3) !important;
}

.invite_section .formCard .formStepsBox .submitButton img {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 10px;
}

.invite_section .formCard .formStepsBox .form-group {
    margin-bottom: 20px;
}

.invite_section .formCard .formStepsBox .form-group .form-control {
    height: 45px;
    border-radius: 4px;
    background: #fff;
    box-shadow: none;
    border: 1px solid #E8E8E8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0A1F44 !important;
}

.invite_section .formCard .formStepsBox .form-group .form-control:focus {
    box-shadow: 0px 0px 0px 2px #0f51a1 !important;
}

.invite_section .formCard .almostdone_box h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    color: #0E50A0;
    margin: 0;
    margin-left: 16px;
}

.invite_section .formCard .almostdone_box p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0A1F44;
    margin-top: 30px;
    max-width: 520px;
    margin-bottom: 45px;
}

.invite_section .formCard .almostdone_box p a {
    color: #0F51A1;
    text-decoration: underline;
}

.almostdone_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.invite_section .formCard .backbutton {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.17px;
    color: #8A94A6;
    position: absolute;
    left: 5%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.invite_section .expiredCard .cardBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 355px;
}

.invite_section .expiredCard .cardBody p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #0A1F44;
    margin: 25px 0 0 0;
}

.invite_section .formCard.w-100.expiredCard {
    max-width: 100%;
}

p.fileName {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    padding: 10px;
    overflow: hidden;
    width: 60%;
}

.register_loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5rem !important;
    height: 5rem !important;
}

.registerEdit.btn,
.registerEdit.btn:hover {
    background: #0F51A1;
    border: 1px solid #0F51A1;
    border-radius: 3px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;

}


.Register_head {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0A1F44;
}

.activeForm label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #8A94A6;

}

.dispalyCard .cardHeader {
    display: none !important;
}

.dispalyCard h3.registerMain {
    display: none;
}

.dispalyCard .steps_mainbox {
    display: none !important;
}

.prefieldData {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0A1F44;
    margin: 0;
}

.previewState {
    border: 1px solid #8A94A6;
    border-radius: 4px;
    background: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #2B3444;
    width: 80px;
    height: 20px;
    margin-left: 23px;
}

@media(max-width:1250px) {
    .invite_section {
        padding: 51px 150px;
    }
}

@media(max-width:992px) {
    .invite_section {
        padding: 51px 80px;
    }
}

@media(max-width:768px) {
    .invite_section {
        padding: 51px 30px;
    }
}

@media(min-width:1441px) {
    .formCard {
        max-width: 800px;
    }

    .invite_section .row .col-md-12.customGrid-xxl {
        justify-content: center;
        display: flex;
    }
}



@media(max-height:850px) {
    section.invite_section {
        height: 100%;
    }
}

@media (max-width:1920px) {
    section.invite_section {
        height: 100%;
        overflow: auto;
    }
}


.form-control.areaCode {
    padding: 0 !important;
}

.group-input::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: #8894A8;
    top: 42px;
    left: 63px;
    z-index: 9999999;
}

.group-input {
    align-items: center;
}

.group-input .form-control {
    border: 0 !important;
    align-items: center !important;
    padding: 0 !important;
    background: transparent !important;
}

.invite_section .formCard .formStepsBox .form-group .group-input .form-control:focus {
    box-shadow: unset !important;
    background: transparent !important;

}

.group-input .form-group {
    margin: 0px !important;
}

/*

@media(min-height:970px){
    section.invite_section{
        height: 100vh!important;
    }
} */