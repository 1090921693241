.dashboardHeader {
    background: linear-gradient(124.48deg, #FCFCFC 7.91%, #F3FCFF 124.02%);
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    padding: 15px 25px !important;
}

.dashboardHeader .navbar-brand {
    padding: 0;
}

.dashboardHeader .navbar-brand h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #464255;
    margin-bottom: 0;
}

.dashboardHeader .navbar-brand p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #464255;
    margin-bottom: 0;
}

.dashboardHeader .middleLogo img {
    mix-blend-mode: luminosity;
}

.dashboardHeader .navbar-collapse {
    flex-grow: inherit;
}

.dashboardHeader .btn-check:focus+.btn,
.dashboardHeader .btn:focus {
    box-shadow: unset;
}

.caret-off::before {
    display: none !important;
}

.caret-off::after {
    display: none !important;
}

.dashboardHeader .navbar-collapse .dropdown .dropdown-toggle .counter {
    background: #FFBB54;
    border: 3px solid #F3F2F7;
    border-radius: 9px;
    width: 34px;
    height: 34px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: -23px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboardHeader .navbar-collapse .dropdown:nth-child(2) .dropdown-toggle .counter {
    background: #0F51A1;
}

.mainAvtarBox {
    /* margin-bottom: 34px; */
    margin-bottom: 29px;
}

.avtarBox {
    /* width: 70px;
    height: 70px;
    border-radius: 100px; */
    overflow: hidden;
}

.avtarBox img {
    width: 100%;
}

.mainAvtarBox h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: #0F51A1;
    margin-top: 10px;
}

.mainAvtarBox p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: #464255;
    margin-bottom: 0.5rem;
}

.mainAvtarBox span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    color: #464255;
    margin-bottom: 0;
}

.sidebarOptions li span.sectionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #AEBEBC;
    padding-left: 13px;
    margin-bottom: 15px;
    display: block;
}

.sidebarOptions li a.collapseBtn {}

.sidebarOptions li .collapseBtnDropdown ul {
    list-style: none;
}

.sidebarOptions li .collapseBtnDropdown ul li {
    margin-bottom: 0;
}

.sidebarOptions li .collapseBtnDropdown ul li a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #464255;
    padding: 10px 20px;
    height: auto;
    position: relative;
}

.sidebarOptions li .collapseBtnDropdown ul li a::after {
    content: "";
    position: absolute;
    height: 0.5px;
    background: #E0E0E2;
    width: 63px;
    bottom: 0;
}

.sidebarOptions li .collapseBtnDropdown ul li:last-child a::after {
    display: none;
}

.sidebarOptions li .collapseBtnDropdown ul li a:hover {
    text-decoration: none;
}

.sidebarOptions li .collapseBtnDropdown ul li a:hover::after {
    display: none;
}

.sidebarOptions li a img.dropdown_down_icon {
    position: absolute;
    right: 0;
    transform: translate(-50%, -50%) rotate(0deg);
    top: 50%;
    transition: .2s;
}

.sidebarOptions li a.collapseBtn[aria-expanded="true"] img.dropdown_down_icon {
    transform: translate(-50%, -50%) rotate(180deg);
}

.logoutModal .modal-content {
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -8px rgba(16, 24, 40, 0.06), 0px 0px 7px -2px rgba(16, 24, 40, 0.08);
    border-radius: 12px;
}

.logoutModal .modal-content .modal-header .logoutAlert {
    border-radius: 50%;
    padding: 11px 22px;
    background: #00ACB9;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0 auto;
}

.logoutModal .modal-dialog {
    width: 495px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
}

.logoutModal .modal-content .modal-header {
    border: 0;
    text-align: center;
    padding: 0;
}

.logoutModal .modal-content .modal-footer {
    border: 0;
    text-align: center;
    padding: 0 15px;
}

.logoutModal .modal-content {
    padding: 16px 30px 22px 32px;
}

.logoutModal .modal-content .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 5px;
}

.logoutModal .modal-content .modal-body h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #1E2524;
}

.logoutModal .modal-content .modal-body p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #667085;
    opacity: 0.75;
    margin-bottom: 22px;
}

.logoutModal .modal-content .modal-footer .logout-cancel {
    border: 1px solid #E9F1F0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #262626;
    padding: 15px 65px;
    border-radius: 10px;
    background: transparent;
}

.logoutModal .modal-content .modal-footer .logout-cancel:hover {
    background-color: #f6f9f9;
}

.logoutModal .modal-content .modal-footer a.logout-btn,
.logoutModal .modal-content .modal-footer button.logout-btn {
    background: #00ACB9;
    box-shadow: 0px 5px 5px rgba(0, 172, 185, 0.08);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 15px 60px;
    outline: 0;
    border: 0;
}

.logoutModal.Custom2 .modal-dialog {
    min-width: 650px;
}

.logoutModal.Custom2 .modal-content .modal-body h3 {
    text-align: center;
}

.logoutModal.Custom2 .modal-content .modal-footer .logout-cancel,
.logoutModal.Custom2 .modal-content .modal-footer button.logout-btn {
    width: 178px;
    height: 48px;
    margin: 0 !important;
}

.logoutModal.Custom2 .modal-content .modal-header .logoutAlert {
    border-radius: 50%;
    padding: 0;
    background: #00ACB9;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 1;
    color: #FFFFFF;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoutModal.Custom2 .modal-content .modal-footer button.logout-btn,
.logoutModal.Custom3 .modal-content button.logout-btn {
    background: #00ACB9;
    box-shadow: 0px 5px 5px rgba(0, 172, 185, 0.08);
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 0;
    outline: 0;
    border: 0;
    margin-left: 35px !important;
}

.logoutModal.Custom3 .modal-content button.logout-btn {
    width: 178px;
    height: 48px;
    margin: 0 !important;
}

.logoutModal.Custom3 .modal-content .modal-title.h4 {
    font-family: Inter, sans-serif !important;
    font-weight: 600;
}

.logoutModal.Custom2 .modal-content p {
    text-align: center;
    margin-bottom: 30px;
}

.logoutModal .modal-content .modal-footer a:hover {
    text-decoration: none;
    background: #029fab;
}

.logoutModal.Custom2 .modal-content .modal-footer {
    justify-content: center !important;
}

.logoutModal.Custom2 .checkBoxRadio {
    cursor: pointer;
}

.logoutModal.Custom2 .checkBoxRadio span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(102, 112, 133, 0.9);
    opacity: 0.75;
}

.logoutModal.Custom2 .checkBoxRadio b {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(102, 112, 133, 0.9);
    text-decoration: underline;
}

.logoutModal.Custom2 .checkBoxRadio input {
    margin-top: 0;
    margin-left: 0;
    position: unset;
    margin-right: 12px;
    cursor: pointer;
    transform: scale(1.4);
}

.logoutModal.Custom2 .checkBoxRadio {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.user_image {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}

.navbar-expand.navbar.dashboard_header {
    display: none;
}

@media screen and (min-width: 320px) and (max-width:768px) {
    .logoutModal .modal-dialog {
        width: 90%;
    }

    .logoutModal .modal-content .modal-footer .logout-cancel,
    .logoutModal .modal-content .modal-footer a.logout-btn {
        padding: 15px 34px;
    }

    .logoutModal .modal-content .modal-body h3 {
        font-size: 17px;
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
    .navbar-expand.navbar.dashboard_header {
        display: block;
    }
}