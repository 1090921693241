.page-desc {
  font-size: 12px;
  font-weight: normal;
  font-family: CircularStd-Book;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  margin-top: 3px;
  color: #8a94a6 !important;
}

.page-desc-left {
  font-size: 14px;
  font-weight: normal;
  font-family: CircularStd-Book;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #8a94a6 !important;
}

.top-open.show {
  display: block;
  top: auto !important;
}

.pad-top th {
  padding-top: 1.75rem;
}

.mob-filter-data {
  position: relative;
}

.conuter-view .viewBtn {
  background: #00ACB9;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FFFFFF;
  width: 90px;
}

.conuter-view .InactiveDropdown:focus {
  box-shadow: none !important;
}

.conuter-view .InactiveDropdown::after {
  content: none !important;
}

.conuter-view .customDropdownBox .dropdown-menu .dropItem,
.conuter-view .customDropdownBox .dropdown-menu .dropItem:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}

.counterfeitTable tr.counter_feit td .status-btn span {
  min-width: auto;
  white-space: nowrap;
}

.counterfeitTable tr.counter_feit td .status-btn {
  width: 123px;
  height: 40px;
  background: #EFF5F5;
  border: 1px solid #E9F1F0;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #344054;
}

.counterfeitTable tr.counter_feit:hover td .status-btn {
  background: #FFFFFF;
  border: 1px solid #99A4A3;
  border-radius: 10px;
}

.counterfeitTable tr.counter_feit td .status-btn .Acknowledged{
  color: #626EE1;
}
.counterfeitTable tr.counter_feit td .status-btn .Assigned{
  color: #564BD7;
}
.counterfeitTable tr.counter_feit td .status-btn .Cancelled{
  color: rgba(198, 15, 15, 0.7);
}
.counterfeitTable tr.counter_feit td .status-btn .completed{
  color: #246A18;
}
.counterfeitTable tr.counter_feit td .status-btn .Reopened{
  color: #397AC9;
}
.counterfeitTable tr.counter_feit td .status-btn .inreview{
  color: #F49E1D;
}
.counterfeitTable tr.counter_feit td .status-btn .inprocess{
  color: #F49E1D;
}

.filter_holder .customDropdownBox .dropdown-custom {
  background: #EFF5F5;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  height: 48px;
  padding: 15px 13px;
  transition: .3s !important;
}

.filter_holder .customDropdownBox .dropdown-custom .staff-value {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #00ACB9;
}

.tableCard table.table.counterfeitTable thead tr.batchesHeader th {
  white-space: nowrap;
}

.tableCard table.table.counterfeitTable thead tr.batchesHeader th:nth-child(3) {
  min-width: 151px !important;
  max-width: 164px !important;
}

.tableCard table.table.counterfeitTable thead tr.batchesHeader th:nth-child(2) {
  min-width: 160px !important;
  max-width: 151px !important;
}

.tableCard table.table.counterfeitTable thead tr.batchesHeader th:nth-child(1) {
  min-width: 166px !important;
  max-width: 162px !important;
}

.filter_holder .customDropdownBox .dropdown-custom:hover {
  background: #F6F9F9 !important;
  transform: translateY(-8px);
  box-shadow: 0px 5px 4px 0px rgba(222, 227, 227, 0.6);
}