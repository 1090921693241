.modal-full-body-counterfeit {
  padding: 3rem !important;
}

.modal-full-body-counterfeit .close {
  padding-right: 0.35rem !important;
  padding-left: 0.35rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: #fff;
  opacity: 1;
  position: absolute;
  left: 92%;
  top: 2%;
  color: #99A4A3;
  font-size: 23px;
  font-weight: 500;
  border-radius: 2px;
  z-index: 1;
  width: 25px;
  height: 25px;
}

.modal-full-body-counterfeit .close:focus {
  outline: 0 !important;
}
.image-modal-view-counterfeit {
  max-width: 500px;
  /* height: 548px; */
}

.left-ar-counterfeit {
  top: 45%;
  position: absolute;
  left: 2%;
}
.right-ar-counterfeit {
  top: 45%;
  left: 92%;
  position: absolute;
}
.footer-img {
  width: 70px;
  height: 70px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  padding: 6px;
}

.gallery-trash {
  position: absolute;
  margin-left: -5.5%;
}
.center-item {
  justify-content: center !important;
}
.product-image-gallery .modal-content {
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -8px rgb(16 24 40 / 6%), 0px 0px 7px -2px rgb(16 24 40 / 8%);
  border-radius: 12px!important;
  overflow: hidden;
}

.product-image-gallery .modal-content .left-ar{
  top: 50%;
  position: absolute;
  left: 5.5%;
  transform: translate(-50%, -50%);
}

.product-image-gallery .modal-content .right-ar{
  top: 50%;
  position: absolute;
  right: 8%;
  transform: translate(-50%, -50%);
}

.product-image-gallery .modal-dialog{
  margin: 8rem auto!important;
  max-width: 650px!important;
}



.productFormBox form .form-group .imageBoxView img.pr-img{
  
}